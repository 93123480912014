/*jshint esversion: 6*/
import t from '../../translate.js'
import * as d3 from "d3";


export function drawNutriWidget(div,data){
  var val=data.results.values;

  var html_N='<div style="text-align: center; font-size:20px;">';
  var html_P='<div style="text-align: center; font-size:20px;">';
  var html_K='<div style="text-align: center; font-size:20px;">';

  var lost=val.n_crop_uptake+val.nitrogen_leaching+val.nitrogen_denitrification+val.nitrogen_volatilization;
  // var percentage_N=1-(val.n_bal/lost);

  var percentage_N=0;
  if (val.n_applied) {
    if (val.n_bal!=0) {
      percentage_N=(val.n_applied/val.n_bal);
    }
    else {
      percentage_N=-1;
    }
  }

  var percentage_P=0;
  if (val.p_applied) {
    if (val.p_bal!=0) {
      percentage_P=(val.p_applied/val.p_bal);
    }
    else {
      percentage_P=-1;
    }
  }

  var percentage_K=0;
  if (val.k_applied) {
    if (val.k_bal!=0) {
      percentage_K=(val.k_applied/val.k_bal);
    }
    else {
      percentage_K=-1;
    }
  }


  var main_N=null;
  if(val.n_bal>0){
    main_N='<b>'+t("Azoto consigliato")+": "+(val.n_bal).toFixed(2)+' kg/ha'+'</b>';
  }
  else{
    main_N='<b>'+t('Azoto consigliato')+': 0 kg/ha</b>';
    // main_N=t("there is a <b>surplus</b> of")+" : "+(-val.n_bal).toFixed(2)+' kg/ha';
  }
  html_N+="<span class='dose'>";
  html_N+=main_N;
  html_N+='<br>'+t('Azoto distribuito')+': '+(val.n_applied).toFixed(2)+' kg/ha';

  if (val.n_bal-val.n_applied>0) {
    html_N+='<br>'+t('Azoto mancante')+': '+(val.n_bal-val.n_applied).toFixed(2)+' kg/ha';
  }

  html_N+="</span>";
  html_N+="</div>";


  var main_P=null;
  if(val.p_bal>0){
    main_P='<b>'+t("Fosforo consigliato")+": "+(val.p_bal).toFixed(2)+' kg/ha'+'</b>';
  }
  else{
    main_P='<b>'+t('Fosforo consigliato')+': 0 kg/ha</b>';
    // main_P=t("there is a <b>surplus</b> of")+" : "+(-val.p_bal).toFixed(2)+' kg/ha';
  }
  html_P+="<span class='dose'>";
  html_P+=main_P;
  html_P+='<br>'+t('Fosforo distribuito')+': '+(val.p_applied).toFixed(2)+' kg/ha';

  if (val.p_bal-val.p_applied>0) {
    html_P+='<br>'+t('Fosforo mancante')+': '+(val.p_bal-val.p_applied).toFixed(2)+' kg/ha';
  }

  html_P+="</span>";
  html_P+="</div>";


  var main_K=null;
  if(val.k_bal>0){
    main_K='<b>'+t("Potassio consigliato")+": "+(val.k_bal).toFixed(2)+' kg/ha'+'</b>';
  }
  else{
    main_K='<b>'+t('Potassio consigliato')+': 0 kg/ha</b>';
    // main_K=t("there is a <b>surplus</b> of")+" : "+(-val.k_bal).toFixed(2)+' kg/ha';
  }
  html_K+="<span class='dose'>";
  html_K+=main_K;
  html_K+='<br>'+t('Potassio distribuito')+': '+(val.k_applied).toFixed(2)+' kg/ha';

  if (val.k_bal-val.k_applied>0) {
    html_K+='<br>'+t('Potassio mancante')+': '+(val.k_bal-val.k_applied).toFixed(2)+' kg/ha';
  }

  html_K+="</span>";
  html_K+="</div>";

  var n_color='#e53935';
  var p_color='#e53935';
  var k_color='#e53935';
  if (val.alert_level_n=='low') {
    n_color='#4caf50';
  }
  if (val.alert_level_p=='low') {
    p_color='#4caf50';
  }
  if (val.alert_level_k=='low') {
    k_color='#4caf50';
  }

  var model='';
  model+="<div class='row'>";
    model+="<div class='col-xs-4' style='text-align:center;'><svg id='model_N_svg' class='nutri_model' text-align:center; style='background:"+n_color+";border-radius:10px; height:160px;width:300px;'></svg> "+html_N+"</div>";
    model+="<div class='col-xs-4' style='text-align:center;'><svg id='model_P_svg' class='nutri_model' text-align:center; style='background:"+p_color+";border-radius:10px; height:160px;width:300px;'></svg> "+html_P+"</div>";
    model+="<div class='col-xs-4' style='text-align:center;'><svg id='model_K_svg' class='nutri_model' text-align:center; style='background:"+k_color+";border-radius:10px; height:160px;width:300px;'></svg> "+html_K+"</div>";
  model+="</div>";
  jQuery(div).html(model);//style="height:160px;width:300px;"

  function arcTween(newAngle) {
    return function(d) {
      var interpolate = d3.interpolate(d.endAngle, newAngle);
      return function(t) {
        d.endAngle = interpolate(t);
        return arc(d);
      };
    };
  }

  var tau = 2 * Math.PI;

  var arc = d3.arc()
      .innerRadius(55)
      .outerRadius(70)
      .startAngle(0);
  var svg_N = d3.select("#model_N_svg"),
      width = 300,
      height = 160,
      g_N = svg_N.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  var circle_N = g_N.append("circle")
  .attr("r", 50 )
  .attr("fill", "white")
  .style("opacity","0.15");

  var main_text_N=g_N.append("text")
  .attr("text-anchor", "middle")
  .attr("alignment-baseline", "alphabetic")
  .text("N")
  .attr("font-family", "sans-serif")
  .attr("font-size", "45px")
  .attr("fill", "white");

  var secondary_text_N=g_N.append("text")
  .attr("text-anchor", "middle")
  .attr("alignment-baseline", "hanging")
  .text(t("Azoto"))
  .attr("x", 0)
  .attr("y", 25)
  .attr("font-family", "sans-serif")
  .attr("font-size", "20px")
  .attr("fill", "white");

  var background_N = g_N.append("path")
      .datum({endAngle: tau})
      .style("fill", "white")
      .style("opacity","0.5")
      .attr("d", arc);
  var foreground_N = g_N.append("path")
      .datum({endAngle: 0 * tau})
      .style("fill", "white")
      .attr("d", arc);

  foreground_N.transition()
      .duration(1000)
      .attrTween("d", arcTween(percentage_N * tau));


  var svg_P = d3.select("#model_P_svg"),
      g_P = svg_P.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  var circle_P = g_P.append("circle")
  .attr("r", 50 )
  .attr("fill", "white")
  .style("opacity","0.15");

  var main_text_P=g_P.append("text")
  .attr("text-anchor", "middle")
  .attr("alignment-baseline", "alphabetic")
  .text("P")
  .attr("font-family", "sans-serif")
  .attr("font-size", "45px")
  .attr("fill", "white");

  var secondary_text_P=g_P.append("text")
  .attr("text-anchor", "middle")
  .attr("alignment-baseline", "hanging")
  .text(t("Fosforo"))
  .attr("x", 0)
  .attr("y", 10)
  .attr("font-family", "sans-serif")
  .attr("font-size", "20px")
  .attr("fill", "white");

  var formula_P=g_P.append("text")
  .attr("text-anchor", "middle")
  .attr("alignment-baseline", "hanging")
  .text("P₂O₅")
  .attr("x", 0)
  .attr("y", 30)
  .attr("font-family", "sans-serif")
  .attr("font-size", "15px")
  .attr("fill", "white");


  var background_P = g_P.append("path")
      .datum({endAngle: tau})
      .style("fill", "white")
      .style("opacity","0.5")
      .attr("d", arc);

  var foreground_P = g_P.append("path")
      .datum({endAngle: 0 * tau})
      .style("fill", "white")
      .attr("d", arc);

  foreground_P.transition()
      .duration(1000)
      .attrTween("d", arcTween(percentage_P * tau));


      var svg_K = d3.select("#model_K_svg"),
          g_K = svg_K.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      var circle_K = g_K.append("circle")
      .attr("r", 50 )
      .attr("fill", "white")
      .style("opacity","0.15");

      var main_text_K=g_K.append("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "alphabetic")
      .text("K")
      .attr("font-family", "sans-serif")
      .attr("font-size", "45px")
      .attr("fill", "white");

      var secondary_text_K=g_K.append("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "hanging")
      .text(t("Potassio"))
      .attr("x", 0)
      .attr("y", 10)
      .attr("font-family", "sans-serif")
      .attr("font-size", "20px")
      .attr("fill", "white");

      var formula_K=g_K.append("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "hanging")
      .text("K₂O")
      .attr("x", 0)
      .attr("y", 30)
      .attr("font-family", "sans-serif")
      .attr("font-size", "15px")
      .attr("fill", "white");


      var background_K = g_K.append("path")
          .datum({endAngle: tau})
          .style("fill", "white")
          .style("opacity","0.5")
          .attr("d", arc);

      var foreground_K = g_K.append("path")
          .datum({endAngle: 0 * tau})
          .style("fill", "white")
          .attr("d", arc);

      foreground_K.transition()
          .duration(1000)
          .attrTween("d", arcTween(percentage_K * tau));

}


export function drawNutriDetail(data){

  var div='';
  div+="<h1>Modello di concimazione</h1>";

  div +='<div id="nutri_histogram" class="row">';
    div +='<div class="n-histogram col-xs-4"></div>';
    div +='<div class="p-histogram col-xs-4"></div>';
    div +='<div class="k-histogram col-xs-4"></div>';
  div +='</div>';

  div +='<div id="nutri_table" class="row">';
    div +='<div class="n-table col-xs-4"></div>';
    div +='<div class="p-table col-xs-4"></div>';
    div +='<div class="k-table col-xs-4"></div>';
  div +='</div>';

  jQuery('#aeco_content').html(div);

  console.log(data);
  var n_data=[];
  var p_data=[];
  var k_data=[];

  var n_data_json={};
  var p_data_json={};
  var k_data_json={};


  jQuery.each(data.results,function(k,v){
    if (k=='n_green_manure' || k=='n_rain' || k=='n_previous_crop' || k=='n_previous_org_fert' || k=='nitrogen_mineralization' || k=='n_applied') {
      n_data.push(parseFloat(v));
      n_data_json[k]=parseFloat(v);
    }
    else if (k=='n_crop_uptake' || k=='nitrogen_leaching' || k=='nitrogen_volatilization' || k=='nitrogen_denitrification') {
      n_data.push(parseFloat(-v));
      n_data_json[k]=parseFloat(-v);
    }


    else if (k=='p_uptake_from_soil' || k=='p_previous_crop' || k=='p_mineralization' || k=='p_applied') {
      p_data.push(parseFloat(v));
      p_data_json[k]=parseFloat(v);
    }
    else if (k=='p_crop_uptake' || k=='p_retro') {
      p_data.push(parseFloat(-v));
      p_data_json[k]=parseFloat(-v);
    }


    else if (k=='k_uptake_from_soil' || k=='k_previous_crop' || k=='k_applied') {
      k_data.push(parseFloat(v));
      k_data_json[k]=parseFloat(v);
    }
    else if (k=='k_crop_uptake' || k=='k_leach') {
      k_data.push(parseFloat(-v));
      k_data_json[k]=parseFloat(-v);
    }
  });

  // Chiamo la funzione che produce l'istogramma per N P K
  drawHistogram(n_data,'#nutri_histogram .n-histogram', t('Azoto'));
  drawHistogram(p_data,'#nutri_histogram .p-histogram', t('Fosforo')+' P₂O₅');
  drawHistogram(k_data,'#nutri_histogram .k-histogram', t('Potassio')+' K₂O');

  // Chiamo la tabella per N P K
  drawTable(n_data_json,'#nutri_table .n-table','N');
  drawTable(p_data_json,'#nutri_table .p-table','P₂O₅');
  drawTable(k_data_json,'#nutri_table .k-table','K₂O');



  jQuery('script[src="sites/all/libraries/external_resources/d3/d3.v4.min.js"]').remove();
  jQuery.getScript( "//cdnjs.cloudflare.com/ajax/libs/d3/3.5.4/d3.min.js", function(){
    console.log(d3.version);
  });
  jQuery('script[src="//cdnjs.cloudflare.com/ajax/libs/d3/3.5.4/d3.min.js"]').remove();


  drawTable(data,'#irri_table');
}

function drawTable(data, div,element){
  var table='';
  table+='<table class="table">';
    table+='<thead>';
      table+='<th>Componenti del bilancio</th>';
      table+='<th>Quantità (kg '+element+'/ha)</th>';
    table+='</thead>';

    table+='<tbody>';
      jQuery.each(data,function(k,v){
        var className='zero';
        if (v>0) {
          className='plus';
        }
        else if (v<0) {
          className='minus';
        }

        table+='<tr class="'+className+'">';
          var label='';
          if (element=='N') {
            label=t('Azoto');
          }
          else if (element=='P₂O₅') {
            label=t('Fosforo');
          }
          else if (element=='K₂O') {
            label=t('Potassio');
          }

          label+=' ';
          var splitted=k.split('_');
          var last_word=splitted[splitted.length-1];

          if (last_word=='rain') {
            label+='apportato con la pioggia';
          }
          else if (last_word=='volatilization') {
            label+='perso per volatilizzazione';
          }
          else if (last_word=='denitrification') {
            label+='perso per denitrificazione';
          }
          else if (last_word=='applied') {
            label+='già distribuito';
          }
          else if (last_word.startsWith("leach")) {
            label+='perso per lisciviazione';
          }
          else if (last_word=='crop') {
            label+='apportato con le potature dell’anno precedente';
          }
          else if (last_word=='mineralization') {
            label+='apportato con la mineralizzazione della sostanza organica del suolo';
          }
          else if (last_word=='fert') {
            label+='apportato dal fertilizzante organico distribuito l\'anno precedente';
          }
          else if (last_word=='uptake') {
            label+='asportato dalla coltura';
          }
          else if (last_word=='soil') {
            label+='assorbito dal suolo';
          }
          else if (last_word=='manure') {
            label+='apportato dal sovescio interrato l\'anno precedente';
          }
          else if (last_word=='retro') {
            label+='retrogradato';
          }


          table+='<td>'+label+'</td>';
          table+='<td>'+v.toFixed(2)+'</td>';
        table+='</tr>';
      });
    table+='</tbody>';
  table+='</table>';

  jQuery(div).html(table);

}

function drawHistogram(data,div,name){

  var margin = {top: 30, right: 10, bottom: 10, left: 50},
      width = parseFloat(jQuery(div).width()) - margin.left - margin.right,
      height = 250 - margin.top - margin.bottom;

  var y0 = Math.max(Math.abs(d3.min(data)), Math.abs(d3.max(data)));

  var y = d3.scaleLinear()
      .domain([-y0, y0])
      .range([height,0])
      .nice();

  var x = d3.scaleBand()
      .domain(d3.range(data.length))
      .rangeRound([0, width])
      .padding(0.2);

  var yAxis = d3.axisLeft(y);

  var svg = d3.select(div).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
    .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  svg.selectAll(".bar")
      .data(data)
    .enter().append("rect")
      .attr("class", function(d) { return d < 0 ? "bar negative" : "bar positive"; })
      .attr("y", function(d) { return y(Math.max(0, d)); })
      .attr("x", function(d, i) { return x(i); })
      .attr("height", function(d) { return Math.abs(y(d) - y(0)); })
      .attr("width", x.bandwidth());

  svg.append("g")
      .attr("class", "x axis")
      .call(yAxis);

  svg.append("g")
      .attr("class", "y axis")
    .append("line")
      .attr("y1", y(0))
      .attr("y2", y(0))
      .attr("x1", 0)
      .attr("x2", width);


  svg.append("text")
  .attr("text-anchor", "middle")
  .attr("alignment-baseline", "hanging")
  .text(name)
  .attr("x", width/2)
  .attr("y", -25)
  .attr("font-family", "sans-serif")
  .attr("font-size", "20px")
  .attr("fill", "black");

  svg.append("text")
  .attr("text-anchor", "middle")
  .attr("alignment-baseline", "hanging")
  .text("(kg/ha)")
  .attr("x", -(height/2))
  .attr("y", -40)
  .attr('transform', 'rotate(-90)')
  .attr("font-family", "sans-serif")
  .attr("font-size", "12px")
  .attr("fill", "black");


}

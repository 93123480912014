import * as db from './data.js';
import global from '../../global.js';
import * as tools from '../../tools.js';
import * as dbmng_ext from '../../core/dbmng_ext.js';
import photo from '../../core/photo.js';
import t from '../../translate.js';
import Mosca from './Mosca.js';
import notification from '../../core/notification.js';





//Main page of crop operation
export function show_operation(id_farm_center, id_field){
  jQuery('#footer').html('');

  var fd=db.getFieldData(id_field);
  var html=`
    ...
  `;

  global.theme.render({
    exclude_card:true,
    title: `${fd.plot.name} - ${t('stagione')} ${fd.season.season_name} - ${t('Operazioni Colturali')}`,
    content: `

      <div class="block-title">${t('Elenco delle operazioni')}</div>
      <div style="margin:10px;">
        <button onclick="location.hash='farm/${id_farm_center}/field/${id_field}/new_operation'" class="button fs-button button-large button-fill">${t('Aggiungi una nuova operazione')}</button>
      </div>

      <div id="operation_list"></div>
    `
  });


  table_oo("#operation_list", id_farm_center, fd.operation);

}

//La funzione produce una tabella con 1 riga per ogni operazione
export function table_oo(div, id_farm_center, values, opt){

  if(!opt){
    opt={};
  }
  var filter;
  if(opt.filter){
    filter=opt.filter;
  }
  var fields;
  if(opt.fields){
    fields=opt.fields;
  }


  if(values.length>0){


    //cerca di capire il type
    var type="";
    var filter_field="";
    // debugger
    var id_field=values[0].id_field;
    if(values[0]["id_observation"]){
      type="observation";
      filter_field="id_survey_schema";
    }
    else if (values[0]["id_crop_operation"]) {
      type="operation";
      filter_field="id_crop_operation";
    }

    //se c'è il filtro, filtra la tipologia
    if(filter){
      values=values.filter(v=>v[filter_field]==filter);
    }
    values=values.filter(v=>v.toDelete!=true);

    var a_plus='';
    if (filter) {
      a_plus=`
      <a class="link external" href="#farm/${id_farm_center}/field/${id_field}/new_${type}/${filter}">
        <i style="font-size:2.5em" class="icon f7-icons">plus_app_fill</i>
      </a>
      `;

    }

    jQuery(div).html(`
    <div class="card data-table">


      <table >
        <thead>
          <tr>
            <th>${a_plus}</th>
            ${!fields?``:`<th>${t('Campi')}</th>`}
            <th>Data</th>
            ${filter?'':'<th>Tipo</th><th>Valore</th>'}
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  `);


    jQuery.each(values, function(ko,o){
      var o2=jQuery.extend( {}, o );

      //Cancella i campi che non vanno in visualizzazione
      delete o2.id_field;
      delete o2.id_fields;
      delete o2.date;
      delete o2.sync;
      delete o2.toDelete;
      delete o2.json;
      delete o2.val;
      delete o2.uid;
      delete o2.version;
      delete o2.update_timestamp;
      if(type=='operation'){
        delete o2.id_crop_operation;
        delete o2.id_operation;
        delete o2.id_fields;
      }
      else if(type=='observation'){
        delete o2.id_survey_schema;
        delete o2.id_observation;
      }

      if(filter && ko==0){
        jQuery.each(o2, function(k,v){
          jQuery(div+' thead tr').append(`<th>${global.capitalizeFirstLetter(k)}</th>`);
        });
      }




      var tds='';
      //inserire qui le formattazioni custom
      if(type=='operation' && o.id_crop_operation==3){

        var n1=o.no3?parseFloat(o.no3):0;
        var n2=o.nh4?parseFloat(o.nh4):0;
        var n3=o.urea?parseFloat(o.urea):0;
        var p=o.p2o5?parseFloat(o.p2o5):0;
        var k=o.k2o?parseFloat(o.k2o):0;

        tds=`<td>NPK: ${(n1+n2+n3).toFixed(1)} - ${p.toFixed(1)} - ${k.toFixed(1)}</td>`;

      }
      else if(type=='observation' && o.id_survey_schema==13){
        console.log(o);
        var percentuale=((o.num_si/o.num_no)*100).toFixed(0);
        tds=`<td>${t("Infestazione")}: ${percentuale}%</td>`;

      }
      else if(type=='observation' && o.id_survey_schema==10){
        var attivaVals=['u','l1v','l2v'];
        var attiva=0;

        jQuery.each(attivaVals,function(kv,p){
          if (o[p]) {
            attiva+=o[p];
            console.log(attiva);
          }
        });

        attiva=((attiva/o.tot)*100).toFixed(0);

        var totaleVals=['u','fu'];
        var totale=0;

        jQuery.each(totaleVals,function(kv,p){
          if (o[p]) {
            totale+=o[p];
            console.log(totale);
          }
        });
        totale=((totale/o.tot)*100).toFixed(0);

        tds=`<td>${t("Attiva")}: ${attiva}% ${t("Totale")}: ${totale}%</td>`;

      }
      else if(type=='observation' && o.id_survey_schema==1){

        //
        var trap_name=db.decodeTrap(o.id_trap);
        tds=`<td>${t("Trappola")}: ${trap_name} ${t("Adulti catturati")}: ${o.trap}</td>`;

      }
      else{
        if(filter){
          jQuery.each(o2,function(k,v){
            tds+=`<td>${t(v)}</td>`;
          });
        }
        else{
          tds="<td>";
          var total_num=null;
          var traps_num=0;
          var avg=null;

          jQuery.each(o2,function(k,v){
            var main_tab='{}';
            if (type=='observation') {
              main_tab=db.getSurveySchema(o.id_survey_schema)
            }
            else if (type=='operation') {
              main_tab=db.getCropOperation(o.id_crop_operation)
            }

            var schema=JSON.parse(main_tab.schema);
            var value=v;

            console.log("DD", schema, k);


            var label=(k);
            if(!main_tab.custom && schema[k]) {
              // debugger
              if ((main_tab.id_crop==1 && main_tab.name=='Catture - Tignoletta della vite') || (main_tab.id_crop==2 && main_tab.name=='Catture')) {
                if (!total_num) {
                  total_num=0;
                }

                if (v) {
                  total_num+=parseInt(v);
                  traps_num++;
                }
                avg=total_num/traps_num;

                var schema_last_element=(Object.keys(schema).length-1);

                var name_last_element=null;

                var numerik=-1;
                jQuery.each(schema,function(kschema,vschema){
                  numerik++
                  if (numerik==schema_last_element) {
                    name_last_element=kschema;
                  }
                });

                if (name_last_element==k) {
                  label=t('Media');
                  value=avg.toFixed(2)
                }
                else {
                  value='';
                }
              }
              else {
                if(schema[k].widget && (schema[k].widget=='radio' || schema[k].widget=='select')){
                  if ( Array.isArray(schema[k].voc_val)) {
                    value=schema[k].voc_val.filter(function(f){return f[v]});

                    if (value.length>0) {
                      value=value[0][v];
                    }
                    else {
                      value='---';
                    }
                  }
                  else {
                    value=schema[k].voc_val[v];
                  }
                }
                label=t(schema[k].label);
              }
            }
            if(value!=''){
              tds+=`${t(label)}: ${value}<br/>`;
            }
          });
          tds+="</td>";
        }
      }

      var type_cell="";
      if(!filter){
        type_cell=`<td>${type=='observation'?t(db.resolveSurveySchema(o.id_survey_schema)):t(db.resolveCropOperation(o.id_crop_operation))}</td>`;
      }

      //Risolve (evetualmente) il nome del campo
      var fields_decode="";
      if(fields){

        function decodeFieldName(id_fields, fields){
          var fields_decode="";
          jQuery.each(id_fields, function(k,v){
            jQuery.each(fields, function(k2,v2){
              if(v2.id_field==v){
                fields_decode+=`${v2.name}<br/>`;
              }
            });
          });
          return fields_decode;
        }
        fields_decode="<td>"+decodeFieldName(o.id_fields,fields)+"</td>";
      }

      var tr=jQuery(`
        <tr >
          <td>
            <i onclick="location.hash='farm/${id_farm_center}/field/${o.id_field}/${type}/${o["id_"+type]}'"
              class="icon f7-icons">pencil
            </i>
            <i class="to-delete icon f7-icons">trash
            </i>
          </td>
          ${fields_decode}
          <td>${o.date}</td>
          ${type_cell}
          ${tds}
        </tr>
      `).appendTo(div+" tbody");

      tr.find(".to-delete").on("click", function(){

        global.app.dialog.confirm("Sei sicuro di eliminare il record?", function(){


          db.deleteElement(type, o["id_"+type], function(){
            location.reload();
          });
        });
      });

    });
  }
  else{
    jQuery(div).html(`
      <div>
      ${t('Non ci sono dati')}
      </div>
    `);
  }

}


function deleteOO(type, id){
  console.log(type,id);
}

//Show all the crop observation
export function show_observation(id_farm_center, id_field){

  jQuery('#footer').html('');
  var fd=db.getFieldData(id_field);
  var html=`

  `;
  global.theme.render({
    exclude_card:true,
    title: `${fd.plot.name} - ${t('stagione')} ${fd.season.season_name}`,
    content: `
      <div class="block-title">${t('Elenco dei monitoraggi')}</div>
      <div style="margin:10px;">
        <button onclick="location.hash='farm/${id_farm_center}/field/${id_field}/new_observation'" class="button fs-button button-large button-fill">${t('Aggiungi un nuovo rilievo')}</button>
      </div>

      <div id="observation_list"></div>

    `
  });

  table_oo("#observation_list", id_farm_center, fd.observation);
}

//Show all the crop observation
export function observation(id_farm_center, id_field, id_observation, id_survey_schema){


  var monit_date=global.getTodayDate();
  var old_observation=null;


  //Prende se in modifica l'old_observation
  var new_id_observation=null;
  if (!id_observation) {
    new_id_observation=tools.getGuid();
  }
  else{
    old_observation=db.getObservation(id_observation);
    monit_date=old_observation.date;
  }


  var fd=db.getFieldData(id_field);



  var other_fields=[];
  if(fd.field.id_season){
    //Plot di farmdata season contiene anche id_field e crop_name
    other_fields=db.getFarmDataSeason(id_farm_center,fd.field.id_season).plot;
    // console.log(db.getFarmDataSeason(id_farm_center,fd.field.id_season));
    // debugger
  }

  var opt_field=``;
  var selected_field='';
  var tot_area=0;
  jQuery.each(other_fields, function(kp,p){
    var sel="";

    if (p.field) {
      var field_name=`${p.name} - ${p.field.crop_name}`;
      if(p.field.id_field==id_field || (old_observation && old_observation.id_fields && old_observation.id_fields.indexOf(p.field.id_field)>-1 )){
        sel="selected='selected'";
        selected_field=selected_field+field_name+", ";
        try{
          tot_area=tot_area+parseFloat(p.area);
        }
        catch(e){;}
      }
      opt_field+=`<option data-area="${p.area}" ${sel} value="${p.field.id_field}">${field_name}</option>`;
    }

  });


  var html=`

  `;

  var optss=`<option selected disabled>Scegli la scheda</option>`;

  var ss=db.getSurveySchema();
  console.log(ss);

  var ss_for_crop=ss.filter(function(s){return s.id_crop==fd.field.id_crop || s.id_crop==0});
  var ss_list='';
  if (ss_for_crop.length==0) {
    optss=`<option selected disabled>${t('Non c\'è nessuna scheda di monitoraggio per questa coltura')}</option>`;
    ss_list=t(`Non c'è nessuna scheda di monitoraggio per questa coltura`);
  }

  if (ss_for_crop.length>0) {
    ss_list+='<ul>';
  }

  jQuery.each(ss_for_crop,function(k,v){
    console.log(v.active);
    // debugger
    if(v.active==0) {
      return true;
    }
    optss+=`<option value="${v.id_survey_schema}">${t(v.name)}</option>`;
    ss_list+=`
      <li>
        <label class="item-radio item-radio-icon-end item-content">
          <input type="radio" name="ss_list" value="${v.id_survey_schema}" />
          <i class="icon icon-radio"></i>
          <div class="item-inner">
            <div class="item-title">${t(v.name)}</div>
          </div>
        </label>
      </li>
    `;
  });

  if (ss_for_crop.length>0) {
    ss_list+='</ul>';
  }

  // console.log(v);

  global.theme.render({
    exclude_card:true,
    title: `${fd.plot.name} - ${t('stagione')} ${fd.season.season_name}`,
    content: `

      <div style="margin:10px;">
        <button onclick="location.hash='farm/${id_farm_center}/season/${fd.season.id_season}/trap'" class="button fs-button button-large button-fill">${t('Gestione e rilievi trappole')}</button>
      </div>

      <div class="block-title">${id_observation?t("Modifica monitoraggio"):t("Crea un nuovo monitoraggio")}</div>
      <div class="list inset" id="ss_list_div">${ss_list}</div>

      <div class="list inset">
        <ul>
          <li class="item-content item-input" id="ss_select" style="display:none;">
            <div class="item-inner">
              <div class="item-input-wrap">
                <select id="survey_schema">${optss}</select>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input id="monit_date" type="date" value="${monit_date}" />
              </div>
            </div>
          </li>
          <li>
          <a class="item-link smart-select smart-select-init" data-open-in="popup" data-searchbar="true" data-searchbar-placeholder="Cerca">
            <select id="id_fields" multiple>
              ${opt_field}
            </select>
            <div class="item-content">
              <div class="item-inner" style="width: fit-content;">
                <div class="item-title"></div>
                <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${selected_field}</div>
              </div>
            </div>
          </a>
        </li>
        </ul>
      </div>

      <div id="add_trap" class="list inset"></div>
      <div id="dbmng" class="list inset"></div>

      <div style="display:none;" class="fs-photo block block-strong inset">
        ${id_observation?getPhotoHtml(id_observation):getPhotoHtml(new_id_observation)}
      </div>
    `
  });

  jQuery('#footer').html(`<button style="display:none;" class="button fs-button button-large button-fill" id="save_observation">${t('Salva')}</button>`);

  if (id_observation) {
    fillPhotos(id_observation)
  }
  else {
    fillPhotos(new_id_observation)
  }

  // var id_observation=null;

  jQuery('#monit_date, #survey_schema').change(function(){
    if (jQuery('#monit_date').val()!='' && jQuery('#survey_schema').val()!=null ) {
      jQuery('#save_observation,.fs-photo').show();

      var id_survey_schema=jQuery('#survey_schema').val();
      var theSurveySchema=db.getSurveySchema(id_survey_schema);
      console.log(JSON.parse(theSurveySchema.schema));

      if (theSurveySchema.custom) {
        var custom=null;
        try{
          if (theSurveySchema.custom=='Mosca') {
            custom=new Mosca(id_survey_schema);
          }
          else if (theSurveySchema.custom=='Sostenibilita') {
            custom=new Sostenibilita(id_survey_schema);
          }
          else {
            custom=new Simple(id_survey_schema);
          }
        }
        catch(e){
          console.error(e);
        }

        var custom_html=custom.render(old_observation);
        jQuery('#dbmng').html(custom_html);
        custom.interact(old_observation);

      }
      else {

        var fields = JSON.parse(theSurveySchema.schema);

        //1: catture (verbale generico)
        if(id_survey_schema==1){



          jQuery("#add_trap").html(`<a class="link external" href="#farm/${id_farm_center}/season/${fd.field.id_season}/trap/add_trap">Aggiungi Trappola</a>`);

          fields["id_trap"]={"label": "Trappola", "type": "string", "widget":"select", "voc_val":{}};

          jQuery.each(fd.trap,function(k,v){
            fields["id_trap"].voc_val[v.id_trap]=v.name;
          });

          //serve per pre-selezionare la trappola se chiamata dalla pagina della trappola
          var preselected_trap=null;
          try{
            var tt=location.href.split("?")[1].split("=");
            if(tt[0]=='id_trap'){
              preselected_trap=tt[1];
            }
          }
          catch(e){;}
          if(preselected_trap){
            fields["id_trap"]["default"]=preselected_trap;
          }

        }
        else{
          jQuery("#add_trap").html("");
        }

        var aForm = {};
        aForm.primary_key = Array('id');
        aForm.fields = fields;

        var selectNMWidgetF7=dbmng_ext.extendSelectNMWidgetF7();
        // debugger
        jQuery.each(aForm.fields,function(k,v){
          v.label=t(v.label);
          v.placeholder=t(v.placeholder);

          if (v.voc_val) {
            jQuery.each(v.voc_val,function(kv,voc_val_v){
              console.log(voc_val_v);

              if (typeof v.voc_val[kv]=='string') {
                v.voc_val[kv]=t(voc_val_v);
              }
              else {
                jQuery.each(voc_val_v,function(i,d){
                  voc_val_v[i]=t(d);
                });
              }
            });
          }

          if (v.widget=='select_nm') {
            v.external_widget=selectNMWidgetF7;
          }
        });


        console.log("OBS",fd);



        var observation={};
        // Questa funzione suggerisce i dati per la scheda-giorno-campo se ha già i dati
        var done_obs=fd.observation.filter(
          function(o){
            return o.id_survey_schema==id_survey_schema && o.id_field==id_field && o.date==jQuery('#monit_date').val()
          }
        );

        if (id_observation) {
          renderForm('dbmng',aForm,old_observation);
        }
        else if (done_obs.length==1) {
          global.app.dialog.create({
            title: t('Attenzione'),
            text: t('È già presente un rilievo'),
            buttons: [
              {
                text: '<span id="new_ril">'+t('Inserisci nuovo rilievo')+'</span>',
              },
              {
                text: '<span id="mod_ril">'+t('Modifica rilievo esistente')+'</span>',
              }
            ],
            verticalButtons: true,
            on: {
              open() {
                jQuery('#mod_ril').on('click', () => {
                  observation=done_obs[0];
                  id_observation=observation.id_observation;
                  renderForm('dbmng',aForm,observation);
                });
                jQuery('#new_ril').on('click', () => {
                  renderForm('dbmng',aForm,observation);
                });
              }
            }
          }).open();
        }
        else {
          renderForm('dbmng',aForm,observation);
        }
      }

    }
  });

  jQuery('input[type=radio][name=ss_list]').change(function() {
    jQuery('#ss_select').show();
    jQuery('#ss_list_div').hide();

    jQuery('#survey_schema').val(this.value).change();
  });

  if (id_observation) {
    console.log("id_observation",id_observation);
    jQuery("input[name=ss_list][value=" + old_observation.id_survey_schema + "]").prop('checked', true).change();
  }
  else if (id_survey_schema) {
    console.log("id_survey_schema",id_survey_schema);
    jQuery("input[name=ss_list][value=" + id_survey_schema + "]").prop('checked', true).change();
  }

  jQuery('#save_observation').click(function(){
    var id_survey_schema=jQuery('#survey_schema').val();
    var theSurveySchema=db.getSurveySchema(id_survey_schema);
    var observation={};

    if (theSurveySchema.custom) {
      observation={
        num_no:jQuery('#num_puntura_no').val(),
        num_si:jQuery('#num_puntura_si').val()
      };
    }
    else {
      observation=global.form.getValue();

    }
    observation.date=jQuery('#monit_date').val();
    observation.id_survey_schema=jQuery('#survey_schema').val();
    observation.id_fields=jQuery("#id_fields").val();

    if (id_observation) {
      db.updateObservation(id_observation, id_field, observation, function(){
        history.back()
        db.storeImages();
      });
    }
    else {
      observation.id_observation=new_id_observation;
      db.insertObservation(id_field, observation, function(){
        history.back()
        db.storeImages();
      });
    }
  });
}



export function operation(id_farm_center, id_field, id_operation, id_crop_operation){

  var fd=db.getFieldData(id_field);
  var monit_date=global.getTodayDate();
  var old_operation=null;


  //Prende se in modifica l'old_observation
  if (id_operation) {
    old_operation=db.getOperation(id_operation);
    monit_date=old_operation.date;
  }



  var other_fields=[];
  if(fd.field.id_season){
    //Plot di farmdata season contiene anche id_field e crop_name
    other_fields=db.getFarmDataSeason(id_farm_center,fd.field.id_season).plot;
    // console.log(db.getFarmDataSeason(id_farm_center,fd.field.id_season));
    // debugger
  }


  var opt_field=``;
  var selected_field='';
  var tot_area=0;
  jQuery.each(other_fields, function(kp,p){
    var sel="";

    if (p.field) {
      var field_name=`${p.name} - ${p.field.crop_name}`;
      if(p.field.id_field==id_field || (old_operation && old_operation.id_fields && old_operation.id_fields.indexOf(p.field.id_field)>-1 )){
        sel="selected='selected'";
        selected_field=selected_field+field_name+", ";
        try{
          tot_area=tot_area+parseFloat(p.area);
        }
        catch(e){;}
      }
      opt_field+=`<option data-area="${p.area}" ${sel} value="${p.field.id_field}">${field_name}</option>`;
    }

  });





  var optco=`<option selected disabled>${t('Scegli l\'operazione colturale')}</option>`;
  var co=db.getCropOperation();
  console.log(co);

  var co_list='';
  co_list+='<ul>';
  jQuery.each(co.filter(function(s){return s.id_crop==0 || s.id_crop==fd.field.id_crop}),function(k,v){
    optco+=`<option value="${v.id_crop_operation}">${t(v.name)}</option>`;
    co_list+=`
      <li>
        <label class="item-radio item-radio-icon-end item-content">
          <input type="radio" name="co_list" value="${v.id_crop_operation}" />
          <i class="icon icon-radio"></i>
          <div class="item-inner">
            <div class="item-title">${t(v.name)}</div>
          </div>
        </label>
      </li>
    `;
  });

  co_list+='</ul>';
  // console.log(v);

  global.theme.render({
    exclude_card:true,
    title: `${fd.plot.name} - ${t('stagione')} ${fd.season.season_name}`,
    content: `
      <div class="block-title">${t('Crea una nuova operazione colturale')}</div>
      <div class="list inset" id="co_list_div">${co_list}</div>

      <div class="list inset">
        <ul>
          <li class="item-content item-input" id="co_select" style="display:none;">
            <div class="item-inner">
              <div class="item-input-wrap">
                <select id="crop_operation">${optco}</select>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input id="monit_date" type="date" value="${monit_date}" />
              </div>
            </div>
          </li>
          <li>
          <a class="item-link smart-select smart-select-init" data-open-in="popup" data-searchbar="true" data-searchbar-placeholder="Cerca">
            <select id="id_fields" multiple>
              ${opt_field}
            </select>
            <div class="item-content">
              <div class="item-inner" style="width: fit-content;">
                <div class="item-title"></div>
                <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${selected_field}</div>
              </div>
            </div>
          </a>
        </li>
        </ul>
      </div>

      <div id="dbmng" class="list inset"></div>
      <input id="tot_area" value="${tot_area}" disabled=disabled  type="hidden">
      <div class="block" id="calc_dose"></div>
    `
  });
  jQuery('#footer').html(`<button style="display:none;" class="button fs-button button-large button-fill" id="save_operation">${t('Salva')}</button>`);






  //UPdate the total area
  jQuery("#id_fields").on("change", function(){
    var tot_area=0;
    jQuery("#id_fields option:selected").each(function(kk,vv){

      try{
        tot_area=tot_area+parseFloat(jQuery(vv).attr("data-area"));
      }
      catch(e){;}
      jQuery("#tot_area").val(tot_area);
    });
  });


  jQuery('#monit_date, #crop_operation').change(function(){
    if (jQuery('#monit_date').val()!='' && jQuery('#crop_operation').val()!=null ) {
      jQuery('#save_operation').show();

      var id_crop_operation=jQuery('#crop_operation').val();
      var theCropOperation=db.getCropOperation(id_crop_operation);
      console.log(JSON.parse(theCropOperation.schema));

      var fields = JSON.parse(theCropOperation.schema);
      var aForm = {};
      aForm.primary_key = Array('id');
      aForm.fields = fields;

      var selectNMWidgetF7=dbmng_ext.extendSelectNMWidgetF7();
      jQuery.each(aForm.fields,function(k,v){
        v.label=t(v.label);
        v.placeholder=t(v.placeholder);

        if (v.voc_val) {
          jQuery.each(v.voc_val,function(kv,voc_val_v){
            console.log(voc_val_v);

            if (typeof v.voc_val[kv]=='string') {
              v.voc_val[kv]=t(voc_val_v);
            }
            else {
              jQuery.each(voc_val_v,function(i,d){
                voc_val_v[i]=t(d);
              });
            }
          });
        }

        if (v.widget=='select_nm') {
          v.external_widget=selectNMWidgetF7;
        }
      });


      console.log("OPERATION",fd);

      var operation={};
      // Questa funzione suggerisce i dati per la scheda-giorno-campo se ha già i dati
      var done_obs=fd.operation.filter(
        function(o){
          return o.id_crop_operation==id_crop_operation && o.id_field==id_field && o.date==jQuery('#monit_date').val()
        }
      );

      if (old_operation) {
        renderForm('dbmng',aForm,old_operation,id_crop_operation);
      }
      else if (done_obs.length==1) {
        global.app.dialog.create({
          title: t('Attenzione'),
          text: t(`È già presente un'operazione`),
          buttons: [
            {
              text: '<span id="new_ril">'+t('Inserisci nuova operazione')+'</span>',
            },
            {
              text: '<span id="mod_ril">'+t('Modifica operazione esistente')+'</span>',
            }
          ],
          verticalButtons: true,
          on: {
            open() {
              jQuery('#mod_ril').on('click', () => {
                operation=done_obs[0];
                id_operation=operation.id_operation;
                renderForm('dbmng',aForm,operation, id_crop_operation);
              });
              jQuery('#new_ril').on('click', () => {
                renderForm('dbmng',aForm,operation, id_crop_operation);
              });
            }
          }
        }).open();
      }
      else {
        renderForm('dbmng',aForm,operation, id_crop_operation);
      }

      jQuery('#dbmng .real_widget').change(function(){
        jQuery('#monit_date').unbind().change(function(){
          console.log("non fa più nulla al cambio della data...");
        })
      });


    }
  });



  jQuery('input[type=radio][name=co_list]').change(function() {
    jQuery('#co_select').show();
    jQuery('#co_list_div').hide();

    jQuery('#crop_operation').val(this.value).change();
  });

  if (id_operation) {
    console.log("id_operation",id_operation);
    jQuery("input[name=co_list][value=" + old_operation.id_crop_operation + "]").prop('checked', true).change();
  }
  else if (id_crop_operation) {
    console.log("id_crop_operation",id_crop_operation);
    jQuery("input[name=co_list][value=" + id_crop_operation + "]").prop('checked', true).change();
  }


  jQuery('#save_operation').click(function(){
    var operation=global.form.getValue();
    operation.date=jQuery('#monit_date').val();
    operation.id_crop_operation=jQuery('#crop_operation').val();

    operation.id_fields=jQuery("#id_fields").val();

    //prende i dati della concimazione
    if(operation.id_crop_operation==3){
      operation.id_product=jQuery(".dbmng_form_field_id_product  select").val();
      operation.product_name=jQuery(".dbmng_form_field_product_name input").val();
      operation.uom=jQuery(".dbmng_form_field_uom input").val();
      operation.amount_tot=jQuery(".dbmng_form_field_amount_tot input").val();
      operation.amount_ha=jQuery(".dbmng_form_field_amount_ha input").val();

      operation.dose_no3=jQuery(".dbmng_form_field_dose_n1 input").val();
      operation.dose_nh4=jQuery(".dbmng_form_field_dose_n2 input").val();
      operation.dose_urea=jQuery(".dbmng_form_field_dose_n3 input").val();
      operation.dose_p2o5=jQuery(".dbmng_form_field_dose_p input").val();
      operation.dose_k2o=jQuery(".dbmng_form_field_dose_k input").val();


    }


    if (old_operation) {
      db.updateOperation(id_operation, id_field, operation, function(){
        history.back()
        // location.hash=`farm/${id_farm_center}/field/${id_field}/operation`;
      });
    }
    else {
      db.insertOperation(id_field, operation, function(){
        // location.hash=`farm/${id_farm_center}/field/${id_field}/operation`;
        history.back()
      });
    }
  });
}

//Funzione per poter gestire le interazioni su particolari crop operation
function addSmartInteraction(id_crop_operation, old_operation){

    //Special per irrigazione
    if(id_crop_operation==2){
      jQuery("#id_fields , .dbmng_form_field_water input").on("change", function(){

        var area=parseFloat(jQuery("#tot_area").val());
        var mm=parseFloat(jQuery(".dbmng_form_field_water input").val());
        var txt="";
        if(mm){
          txt=`L'irrigazione proposta è pari a ${mm*10} mc/ha e ${(mm*10*area/10000).toFixed(1)} mc complessivi su una superficie di ${(area/10000).toFixed(1)}ha.`
        }
        else{
          txt+=t("Inserisci l'irrigazione in mm");
        }
        jQuery("#calc_dose").html(txt);
      });

      jQuery(".dbmng_form_field_water input").trigger("change");
    }
    //Special per irrigazione e per semina
    if(id_crop_operation==7 || id_crop_operation==9){

      //campo contiene utila selezionata
      jQuery(".dbmng_form_field_type_amount").hide();

      jQuery("#id_fields").on("change", function(){

        var type=jQuery('.dbmng_form_field_type_amount input').val();
        console.log(type);
        if(type==''){type='tot';}

        jQuery(".dbmng_form_field_amount_"+type+" input").trigger("change");

      });

      jQuery(".dbmng_form_field_amount_tot input").on("change", function(){

        var area=parseFloat(jQuery("#tot_area").val());
        var amount=parseFloat(jQuery(".dbmng_form_field_amount_tot input").val());

        var amount_ha=amount/(area/10000);

        jQuery(" .dbmng_form_field_amount_ha input" ).val(amount_ha.toFixed(3));
        jQuery(" .dbmng_form_field_type_amount input" ).val("tot");
      });

      jQuery(".dbmng_form_field_amount_ha input").on("change", function(){

        var area=parseFloat(jQuery("#tot_area").val());
        var amount_ha=parseFloat(jQuery(".dbmng_form_field_amount_ha input").val());

        var amount =amount_ha * (area/10000);

        jQuery(" .dbmng_form_field_amount_tot input" ).val(amount.toFixed(3));
        jQuery(" .dbmng_form_field_type_amount input" ).val("ha");
      });


    }
    if(id_crop_operation==3){

      var fert=db.getFertilizer();

      var prd="<option disabled>"+t("Scegli un concime")+"</option>";
      jQuery.each(fert, function(kf,f){
        prd+=`<option data-no3="${f.no3?f.no3:0}" data-nh4="${f.nh4?f.nh4:0}"  data-urea="${f.urea?f.urea:0}"
              data-p2o5="${f.p2o5?f.p2o5:0}"  data-k2o="${f.k2o?f.k2o:0}" value="${f.code}">${t(f.name)}
            </option>`
      });
      prd+=`<option  data-no3="0" data-nh4="0"  data-urea="0"  data-p2o5="0"  data-k2o="0" value="xxx">${t('Altro')}</option>`;

      //nascondi i campi
      jQuery(".dbmng_form_field_k2o, .dbmng_form_field_p2o5, .dbmng_form_field_no3, .dbmng_form_field_nh4, .dbmng_form_field_urea").hide();

      //Aggiungi i nuovo campi
      jQuery(".dbmng_form_field_no3").closest('ul').prepend(`
        <div class="row">
          <div class="col-75">
            <div class="item-content item-input dbmng_form_field_id_product">
              <div class="item-inner">
                <div class="item-title item-label">${t('Tipo Prodotto')}</div><div class="item-input-wrap">
                  <select class="real_widget">
                    $${prd}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-25" style="margin: auto;">
            <button class="button button-primary" id="add_new_product"><i class="f7-icons" style="font-size: 30px;">plus_circle_fill</i></button>
          </div>


          <div class="col-100">
            <div class="item-content item-input dbmng_form_field_product_name">
              <div class="item-inner">
                <div class="item-title item-label">${t('Nome Commerciale')}</div><div class="item-input-wrap">
                  <input type="text" class="real_widget"></input>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
        <div class="row">
          <div class="col-25">
            <div class="item-content item-input dbmng_form_field_uom">
              <div class="item-inner">
                <div class="item-title item-label">${t('Unità')}</div><div class="item-input-wrap">
                  <select>
                    <option value="1">kg</option>
                    <option value="100">q</option>
                    <option value="1000">T</option>
                    <option value="1">l</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-35">
            <div class="item-content item-input dbmng_form_field_amount_tot">
              <div class="item-inner">
                <div class="item-title item-label">${t('Peso Totale')}</div><div class="item-input-wrap">
                  <input type="number" class="real_widget"></input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-40">
            <div class="item-content item-input dbmng_form_field_amount_ha">
              <div class="item-inner">
                <div class="item-title item-label">${t('Peso per ettaro')}</div><div class="item-input-wrap">
                  <input type="number" class="real_widget"></input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="display:none;" class="fertilizisation_dose row">
          <div class="col-20">
            <div class="item-content item-input dbmng_form_field_dose_n1">
              <div class="item-inner">
                <div class="item-title item-label">${t('NO3')}</div><div class="item-input-wrap">
                <input type="number" class="real_widget"></input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-20">
            <div class="item-content item-input dbmng_form_field_dose_n2">
              <div class="item-inner">
                <div class="item-title item-label">${t('NH4')}</div><div class="item-input-wrap">
                <input type="number" class="real_widget"></input>
                </div>
              </div>
            </div>
          </div>
        <div class="col-20">
            <div class="item-content item-input dbmng_form_field_dose_n3">
              <div class="item-inner">
                <div class="item-title item-label">${t('Urea')}</div><div class="item-input-wrap">
                <input type="number" class="real_widget"></input>
                </div>
              </div>
            </div>
          </div>
        <div class="col-20">
            <div class="item-content item-input dbmng_form_field_dose_p">
              <div class="item-inner">
                <div class="item-title item-label">${t('P2O5')}</div><div class="item-input-wrap">
                <input type="number" class="real_widget"></input>
                </div>
              </div>
            </div>
          </div>
        <div class="col-20">
        <div class="item-content item-input dbmng_form_field_dose_k">
          <div class="item-inner">
            <div class="item-title item-label">${t('K2O')}</div><div class="item-input-wrap">
            <input type="number" class="real_widget"></input>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>


        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">${t('Unità/ha')}</div><div id="fert_unit" class="item-input-wrap">
              ${t('Scegli un prodotto ed inserisci una quantità')}...
            </div>
          </div>
        </div>
      `);


      jQuery("#add_new_product").on("click", function(){
        //start modal
        var dynamicSheet = global.app.sheet.create({
          content: `
          <div class="sheet-modal" style="height: 80%;">
            <div class="toolbar">
              <div class="toolbar-inner">
                <div class="left"></div>
                <div class="right">
                  <a class="link sheet-close">${t('Salva')}</a>
                </div>
              </div>
            </div>
            <div class="sheet-modal-inner">
              <div class="block" id="edit_product">

                <div class="col-100">
                  <div class="item-content item-input dbmng_form_field_product_name_modal">
                    <div class="item-inner">
                      <div class="item-title item-label">${t('Nome Commerciale')}</div><div class="item-input-wrap">
                        <input type="text" class="real_widget"></input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-100">
                  <div class="item-content item-input dbmng_form_field_dose_n1_modal">
                    <div class="item-inner">
                      <div class="item-title item-label">${t('NO3')}</div>
                      <div class="item-input-wrap">
                        <input type="number" class="real_widget"></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-100">
                  <div class="item-content item-input dbmng_form_field_dose_n2_modal">
                    <div class="item-inner">
                      <div class="item-title item-label">${t('NH4')}</div><div class="item-input-wrap">
                      <input type="number" class="real_widget"></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-20">
                  <div class="item-content item-input dbmng_form_field_dose_n3_modal">
                    <div class="item-inner">
                      <div class="item-title item-label">${t('Urea')}</div><div class="item-input-wrap">
                      <input type="number" class="real_widget"></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-20">
                  <div class="item-content item-input dbmng_form_field_dose_p_modal">
                    <div class="item-inner">
                      <div class="item-title item-label">${t('P2O5')}</div><div class="item-input-wrap">
                      <input type="number" class="real_widget"></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-20">
                  <div class="item-content item-input dbmng_form_field_dose_k_modal">
                    <div class="item-inner">
                      <div class="item-title item-label">${t('K2O')}</div><div class="item-input-wrap">
                      <input type="number" class="real_widget"></input>
                      </div>
                    </div>
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
          `,
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');

              
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
            closed: function (sheet) {
              console.log('Sheet done');

              var product={
                guid: tools.getGuid(),
                product_name: jQuery(".dbmng_form_field_product_name_modal input").val(),
                dose_n1: jQuery(".dbmng_form_field_dose_n1_modal input").val(),
                dose_n2: jQuery(".dbmng_form_field_dose_n2_modal input").val(),
                dose_n3: jQuery(".dbmng_form_field_dose_n3_modal input").val(),
                dose_p: jQuery(".dbmng_form_field_dose_p_modal input").val(),
                dose_k: jQuery(".dbmng_form_field_dose_k_modal input").val()
              }

              jQuery(".dbmng_form_field_product_name input").val(product.product_name);
              jQuery(".dbmng_form_field_dose_n1 input").val(product.dose_n1);
              jQuery(".dbmng_form_field_dose_n2 input").val(product.dose_n2);
              jQuery(".dbmng_form_field_dose_n3 input").val(product.dose_n3);
              jQuery(".dbmng_form_field_dose_p input").val(product.dose_p);
              jQuery(".dbmng_form_field_dose_k input").val(product.dose_k);



              jQuery(".dbmng_form_field_id_product select").prepend(`
                <option 
                  data-no3="${product.dose_n1}"
                  data-nh4="${product.dose_n2}"
                  data-urea="${product.dose_n3}"
                  data-p2o5="${product.dose_p}"
                  data-k2o="${product.dose_k}"

                  value="${product.guid}">${product.product_name}
                </option>
              `).val(product.guid);

            }
          }
        });
        dynamicSheet.open();

      });

    //prende i dati inseriti
    if(old_operation){

      if(!old_operation.id_product){
        jQuery(".dbmng_form_field_k2o, .dbmng_form_field_p2o5, .dbmng_form_field_no3, .dbmng_form_field_nh4, .dbmng_form_field_urea").show();
      }


      jQuery(".dbmng_form_field_id_product  select").val(old_operation.id_product);
      jQuery(".dbmng_form_field_product_name input").val(old_operation.product_name);
      jQuery(".dbmng_form_field_uom input").val(old_operation.uom);
      jQuery(".dbmng_form_field_amount_tot input").val(old_operation.amount_tot);
      jQuery(".dbmng_form_field_amount_ha input").val(old_operation.amount_ha);

      jQuery(".dbmng_form_field_amount_ha input").val(old_operation.amount_ha);

      jQuery(".dbmng_form_field_dose_n1 input").val(old_operation.dose_no3);
      jQuery(".dbmng_form_field_dose_n2 input").val(old_operation.dose_nh4);
      jQuery(".dbmng_form_field_dose_n3 input").val(old_operation.dose_urea);
      jQuery(".dbmng_form_field_dose_p input").val(old_operation.dose_p2o5);
      jQuery(".dbmng_form_field_dose_k input").val(old_operation.dose_k2o);


      updateFert();
    }


      //se cambio il totale o i campi aggiorno la produzione per ettaro
      jQuery(".dbmng_form_field_amount_tot input, #id_fields   ").on("change", function(){






        var area=parseFloat(jQuery("#tot_area").val())/10000;
        var tot=parseFloat(jQuery(".dbmng_form_field_amount_tot input").val());


        jQuery(".dbmng_form_field_amount_ha input").val((tot/area).toFixed(2)).trigger("change");
        console.log("tot cambia ha");

        //non serve update viene lanciato
      });

      //se cambio il prodotto per ettaro aggiorno il totale
      jQuery(".dbmng_form_field_amount_ha input ").on("change", function(){
        var area=parseFloat(jQuery("#tot_area").val())/10000;
        var prod_ha=parseFloat(jQuery(".dbmng_form_field_amount_ha input").val());
        jQuery(".dbmng_form_field_amount_tot input").val((prod_ha*area).toFixed(2));
        console.log("ha cambia tot");
        updateFert();
      });


      //se cabio prodotto
      jQuery(".dbmng_form_field_id_product select  ").on("change", function(){


          var prd=jQuery('.dbmng_form_field_id_product option:selected');

          jQuery(".dbmng_form_field_dose_n1 input").val(prd.attr("data-no3"));
          jQuery(".dbmng_form_field_dose_n2 input").val(prd.attr("data-nh4"));
          jQuery(".dbmng_form_field_dose_n3 input").val(prd.attr("data-urea"));
          jQuery(".dbmng_form_field_dose_p input").val(prd.attr("data-p2o5"));
          jQuery(".dbmng_form_field_dose_k input").val(prd.attr("data-k2o"));

          updateFert();

      });

      //unità di misura
      jQuery(".dbmng_form_field_uom select, .dbmng_form_field_dose_n1 input, .dbmng_form_field_dose_n2 input, .dbmng_form_field_dose_n3 input,.dbmng_form_field_dose_p input,.dbmng_form_field_dose_k input ").on("change", function(){
        console.log("cambia prodotto")
        updateFert();
      });
    }
}

//aggiorna i dati della fertilizzazione
function updateFert(){
  console.log("fert");

  var prd=jQuery(".dbmng_form_field_id_product option:selected");

  var no3=parseFloat(jQuery(".dbmng_form_field_dose_n1 input").val());
  var nh4=parseFloat(jQuery(".dbmng_form_field_dose_n2 input").val());
  var urea=parseFloat(jQuery(".dbmng_form_field_dose_n3 input").val());
  var p2o5=parseFloat(jQuery(".dbmng_form_field_dose_p input").val());
  var k2o=parseFloat(jQuery(".dbmng_form_field_dose_k input").val());

  var amount_ha=jQuery(".dbmng_form_field_amount_ha input").val();
  var uom_moltiplica=parseFloat(jQuery(".dbmng_form_field_uom select").val());

  console.log(amount_ha,uom_moltiplica,nh4);

  if(amount_ha && prd.length>0){

    amount_ha=parseFloat(amount_ha);

    var n1=(uom_moltiplica*amount_ha*no3/100.0);
    var n2=(uom_moltiplica*amount_ha*nh4/100.0);
    var n3=(uom_moltiplica*amount_ha*urea/100.0);
    var p=(uom_moltiplica*amount_ha*p2o5/100.0);
    var k=(uom_moltiplica*amount_ha*k2o/100.0);

    jQuery(".dbmng_form_field_no3 input").val(n1);
    jQuery(".dbmng_form_field_nh4 input").val(n2);
    jQuery(".dbmng_form_field_urea input").val(n3);
    jQuery(".dbmng_form_field_p2o5 input").val(p);
    jQuery(".dbmng_form_field_k2o input").val(k);

    jQuery("#fert_unit").html(`NPK: ${(n1+n2+n3).toFixed(1)} - ${p.toFixed(1)} - ${k.toFixed(1)}`);

  }
}

function getPhotoHtml(id_obersvation){
  var html='';
  html+="<div id='photo'>";
    html+='<p class="row">';
      if (!global.is_cordova()) {
        html+='<input style="display:none;" id="scatta_foto" class="scatta_foto col" type="file" accept="image/*"></input>';
        html+='<button id="scatta_foto_btn" class="scatta_foto col button button-large button-raised" type="file" accept="image/*"><span>'+t('Aggiungi')+'</span> <i class="icon f7-icons">camera_fill</i></button>';

      }
      else {
        html+='<button id="scatta_foto" class="scatta_foto col button button-large button-raised" type="file" accept="image/*"><span>'+t('Aggiungi')+'</span> <i class="icon f7-icons">camera_fill</i></button>';
      }
    html+='</p>';

    try {
      html+=photo.getPhotoDiv(id_obersvation);
    }
    catch (e) {
      global.crash_log(e,"photo.getPhotoDiv(id_obersvation) -");
    }
  html+="</div>";
  return html;
}

function fillPhotos(id_obersvation){
  // debugger
  photo.fillPhotoDiv(id_obersvation);

  if (global.is_cordova()) {
    jQuery("#scatta_foto").click(function() {
      photo.takePicture(id_obersvation);
    });

  }
  else {
    jQuery("#scatta_foto").change(function() {
      photo.takePicture(id_obersvation);
    });

    jQuery('#scatta_foto_btn').click(function(){
      // debugg
      jQuery("#scatta_foto").click();
    });
  }
}


function renderForm(div,aForm,dataVal, id_crop_operation){
  var theme_f7 = new Dbmng.Framework7Theme();

  if(aForm.fields && !aForm.fields["notes"]){
    aForm.fields["notes"]={"label": "Note"}
  }

  global.form=new Dbmng.Form({aForm:aForm, aParam:{exclude_card:true}, theme:theme_f7});
  jQuery('#'+div).html(global.form.createForm(dataVal));


  //Interazioni avanzate per determinate crop operation
  if(id_crop_operation){
    addSmartInteraction(id_crop_operation, dataVal);
  }



}

/*jshint esversion: 6 */
import './style.css';

import '../fairshare_style.css';

import storage from '../../../storage.js';
import global from '../../../global.js';
import dbmng from '../../../ext/dbmng.js';

import * as pairing from './atlas_pairing.js';


// ---- file duplicato da ripulire ----
import * as partecipa from '../../../core/partecipa/partecipa_new.js';
// Se serve GPS
//*********************************************************************
import gps from '../../../core/gps.js';
//*********************************************************************


// TRADUZIONE
//*********************************************************************
import t from '../../../translate.js';
import * as Login from  '../../../login.js';
//*********************************************************************

import * as db from '../data.js';
import fairshare_routes from '../fairshare_routes.js';

// Se serve LEAFLET
//*********************************************************************
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

import 'leaflet.markercluster/dist/leaflet.markercluster-src.js';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';


delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
//*********************************************************************


// Variabili globali
//*********************************************************************
global.app_version='1.0.37';

global.icon='icons/atlas_mobile.png';



// global.base_link='http://localhost:8080/';
// global.base_path='http://localhost/fairshare/api/';
global.base_link='https://atlas-app-staging.aedit.it/';
global.base_path='https://atlas-staging.aedit.it/api/';
global.chat_url='http://localhost:9105/';

if (location.origin!='http://localhost:8080') {
  global.base_link='https://atlas-app-staging.aedit.it/';
  global.base_path='https://atlas-staging.aedit.it/api/';
  global.chat_url='https://atlas.aedit.it/';
}


global.use_oauth2={
  "label": "Atlas Access",
  "client_id": "atlas",
  "client_secret": "581fac6c-a071-421a-a1b6-56315e3df899",
  "redirect_uri": location.origin,
  "endpoint": "https://goodgo.aedit.it/auth/realms/atlas/protocol/openid-connect/"
}


global.base_call=global.base_path+'userplus/';
global.base_call_aedita=global.base_path;
global.nomeApp='ATLAS';
global.useSocialLogin=true;
global.language='en';
global.settingsName='atlas';
//*********************************************************************

// global.firebaseConfig = {
//   apiKey: "AIzaSyAKIbLKalc8AzL63_4KJaA89XfJaF7oNAs",
//   authDomain: "agroambiente-abruzzo.firebaseapp.com",
//   projectId: "agroambiente-abruzzo",
//   storageBucket: "agroambiente-abruzzo.appspot.com",
//   messagingSenderId: "344628648210",
//   appId: "1:344628648210:web:e3cb33eb2de3a4b659fbcb",
//   measurementId: "G-8B4ZRSF00N"
// };
//
// if (typeof firebase!=='undefined') {
//   global.firebase=firebase.initializeApp(global.firebaseConfig);
//
//   global.messaging = null;
//
//   if (firebase.messaging.isSupported()){
//     global.messaging = firebase.messaging();
//   }
// }


//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  router=partecipa.exe_routes(router);
  router=fairshare_routes(router);


  router.on({
    'atlas_pairing': function () {
      pairing.atlas_pairing();
    },
    'atlas_pairing/:service_id': function (params) {
      pairing.atlas_pairing(params.service_id);
    },

  });



  return router;
}

export function tools(){
  partecipa.partecipa_tools();
}


function cleanPage(){
  var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">'+('Back')+'</span></a>';
  jQuery('.left').html(html);

  jQuery('#download_app').hide();

  jQuery('#footer').parent().show();
  jQuery('#footer').html('');
}

export function home_page(){
  var s=storage.get();
  var title='ATLAS';

  if (s.user) {
    if (s.id_access_to_grant) {
      location.hash='grant_access/'+s.id_access_to_grant;
    }
    else if (s.farms && s.farms.length>0) {
      var farm_role='';
      jQuery.each(s.farms,function(k,v){
        if (farm_role=='' || farm_role=='azienda') {
          farm_role=v.farm_role;
        }
      });

      if (farm_role=='azienda') {
        if (s.farms.length==1) {
          location.hash='farm/'+s.farms[0].id_farm_center;
        }
        else {
          // Todo: va bene prendere il primo campo? O meglio l'ultimo usato?
          location.hash='farm/'+s.farms[0].id_farm_center;
          // alert(`Evvai azienda! Ho trovato ${s.farms.length} aziende collegate a te`);
        }
      }
      else {
        if(location.search){
          window.location.href =  window.location.href.split("?")[0]+'#farms'; //"http://www.newurl.com";
        }
        else {
          location.hash='farms';
        }
      }
    }
    else {
      // Nessuna azienda è stata trovata per l'utente. Nè come agronomo né come azienda, facciamo scegliere all'utente che tipologia di utente sia
      location.hash='start';
    }
  }
  else {
    location.hash='login';
  }
}

export function get_theme_param(theme_param){
  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},
    {'label': t("Modifica l'azienda"), 'icon_f7':'building', 'href':'#farm_edit','class':'custom_link_farm_edit'},
    {'label': t('Gestisci le aziende'), 'icon_f7':'building_2_fill', 'href':'#farms'},
    {'label': t('Accessi all\'azienda'), 'icon_f7':'person_3_fill', 'href':'#access','class':'custom_link_access'},
    {'label': t('Analisi del suolo'), 'icon_f7':'triangle_righthalf_fill', 'href':'#soil','class':'custom_link_soil'},
    {'label': t('Atlas pairing'), 'icon_f7':'arrow_2_circlepath_circle_fill', 'href':'#atlas_pairing','class':'custom_link_atlas'}
  ];

  var s=storage.get();
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;

    var showPartecipa=false;
    if (showPartecipa) {
      sidebars.push({'label': 'Partecipa', 'icon_f7':'doc_append', 'href':'#partecipa'});
    }
  }

  theme_param.sidebars=sidebars;

  theme_param.title='<img src="'+global.icon+'" height="35" />';

  theme_param.ptr=false; // attiva/disattiva pull to refresh

  return theme_param;
}

export function synchronize(){
  db.sync(function(){console.log("Sync");},function(e){console.log(e);});
}

import global from '../../../global.js';
import t from '../../../translate.js';
import * as Login from  '../../../login.js';
import storage from '../../../storage.js';

export function atlas_pairing(service_id_to_connect){

    var s=storage.get();

    let session_state=null;
    let code=null;


    //verifica se torma il code dall'oauth2 esterno
    if(window.location.search.startsWith("?") || window.location.hash.split("?").length>=2 ){


      let string_to_search=window.location.search;
      if(!string_to_search.startsWith("?")){
        string_to_search=window.location.hash;
      }

      var params=string_to_search.split("?")[1].split("&");
      jQuery.each(params, function(kp,p){

        var pp=p.split("=");
        if(pp.length==2){
          if(pp[0]=='session_state'){
            session_state=pp[1];
          }
          else if(pp[0]=='code'){
            code=pp[1];
          }
        }
      });

    }


    if(code){

        pairingOauth2(service_id_to_connect,  code);
    }
    else{


        global.theme.render({
        exclude_card:true,
        title: ` ${t('Atlas Pairing')}`,
        content: `
            <h3>Service</h3>
            <div id="atlas_service_error"></div>
            <div class="card data-table">
              <table>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>paired</th>
                    <th>get field</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody id="paired_services">
                </tbody>
              </table>
            </div>



        `
        });
        // <h3>Pair or update a new service</h3>
        // <select id="service_selector">
        // </select>
        // <button class="pair_service">Pair</button>


        var chat_url = global.chat_url;
        jQuery.ajax({
        contentType: "application/json; charset=utf-8",
        type: 'GET',
        url: chat_url+"atlas/api/v1/atlas_services",
        dataType: "json",
        headers: {
                'Authorization':'Bearer '+s.user.social_token
            },
        success: function(res){
            console.log(res);
            var service=null;
            // debugger


            if(!res.ok){
              jQuery("#atlas_service_error").html(res.message);
            }
            else if ((res.data.length==0)){
              jQuery("#atlas_service_error").html("No service available");
            }

            jQuery.each(res.data, function(k,i){
            let json=i.json
            if(i.service_id==service_id_to_connect){
                service=i;
            }
            jQuery("#service_selector").append(`
                <option value="${i.service_id}">${i.json.description}</option>
            `);

            if(i.service_id!=='it.aedit.atlas.field_data'){
              jQuery("#paired_services").append(`
                  <tr>
                    <th>${i.json.description}</th>
                    <td>${i.uid?"Paired":"Not paired"}</td>
                    <td></td>
                    <td>${i.uid?"<a service_id='"+i.service_id+"' class='unpair_service'>Delete pairing</a>":"<a service_id='"+i.service_id+"' class='pair_service'>Pair</a>"}</td>
                  </tr>
              `);
            }

            });


            // if(res.pairing.data.length==0){
            //     jQuery("#paired_services").html("There are no paired service");
            // }
            // else{
            //     jQuery("#paired_services").html(`

            //     `);
            // }

            // jQuery.each(res.data.pairing, function(k,p){
            //     let json=i.json
            //     if(i.service_id==service_id_to_connect){
            //       service=i;
            //     }
            //     jQuery("#paired_services").append(`
            //       <tr>
            //         <th>....</th>
            //       </tr>
            //     `);
            //   });



            jQuery(".unpair_service").click(function(){
                var service_id=jQuery(this).attr("service_id");
                unpairService(service_id);
            });

            jQuery(".pair_service").on("click", function(){
            // let service_id=jQuery("#service_selector").val();
            let service_id=jQuery(this).attr("service_id");

            let ss=res.data.filter(function(i){return i.service_id==service_id});
              if(ss.length>=1){
                let service=ss[0];
                console.log(service);

                let endpoint=service.json.pairing.oauth2_auth_url;
                endpoint=endpoint.substr(0, endpoint.length-4);

                let oauth2_url=Login.GetOAuthURLNew({
                "redirect_uri": location.origin+"/#atlas_pairing/"+service_id,
                "endpoint": endpoint,
                "client_id": service.client_id,
                "response_type": "code",
                "scope": " "
                });
                console.log(oauth2_url)
                window.location.href=oauth2_url;

              }
              else{
                alert("service not found");
              }

            });
        },
        error: function(e){
            console.log(e)

            jQuery("#atlas_service_error").html("Error loading services. Please reload the page");
        }
        });
    }
  }

  function unpairService(service_id){
    if(confirm(t("Are you sure to delete the pairing?"))){
      var s=storage.get();
      var chat_url = global.chat_url;
      jQuery.ajax({
        contentType: "application/json; charset=utf-8",
        type: 'DELETE',
        url: chat_url+"atlas/api/v1/atlas_services/pairing/"+service_id,
        dataType: "json",
        headers: {
          'Authorization':'Bearer '+s.user.social_token
        },
        success: function(res){
          console.log(res);
          location.reload();
        },
        error: function(e){
          console.log(e)
        }
      });  
    }
  }

  export function pairingOauth2(service_id_to_connect,  code){





    global.theme.render({
        exclude_card:true,
        title: ` ${t('Atlas Pairing')}`,
        content: `

            <div id="pairing_result"></div>
        `
        });

    // debugger;

    var s=storage.get();
    if (s.user) {

        var chat_url = global.chat_url;
        jQuery.ajax({
        contentType: "application/json; charset=utf-8",
        type: 'GET',
        headers: {
            'Authorization':'Bearer '+s.user.social_token
        },
        url: chat_url+"atlas/api/v1/atlas_services/pairing/"+service_id_to_connect+"/"+code,
        dataType: "json",
        success: function(res){



            if(typeof(res.ok)!=="undefined" && !res.ok){
                jQuery("#pairing_result").html(`
                    <h3>Error during pairing</h3>
                    ${res.message}
                    ${res.json?res.json.error_description:""}
                `);
            }
            else{
                jQuery("#pairing_result").html(`
                    <h3>The service has been paired successfull</h3>
                    <button id="go_back">Back</button>
                `);

                jQuery("#go_back").on("click", function(){
                    window.location.href=window.location.origin+"?#atlas_pairing"
                });
            }
        },
        error: function(e){
            alert("Pairing API not working")
        }
        });
    }
    else{
        alert("not authorized")
    }
  }

/*jshint esversion: 6*/
import t from '../../translate.js'
import * as d3 from "d3v4";//
import * as c3 from 'c3';
import 'c3/c3.css';

import './smartipm.css';
import './martina_theme.css';
// MoscaWidget.js
//
/*jshint loopfunc: true */

//PATH MOSCA:
// M31,14 C31,16 32,17 30,19 C30,19 30,19 30,19 C31,20 32,22 32,24 C32,24 38,15 38,15 C37,15 39,10 39,10 C39,10 38,15 38,15 C39,15 33,25 33,25 C35,30 35,32 36,33 C35,33 35,33 35,33 C35,33 35,33 35,33 C35,33 36,34 36,35 C36,35 36,35 36,35 C36,35 36,35 36,35 C36,35 38,33 40,32 C41,30 42,29 42,29 C42,29 41,31 39,32 C38,34 37,35 37,35 C41,43 50,62 45,71 C42,76 29,55 28,58 C28,57 27,62 22,58 C20,53 14,75 6,73  C-2,70 8,45 12,35  C12,35 12,35 12,35 C12,35 12,35 12,35 C12,35 12,35 12,35 C12,35 12,35 12,35 C12,35 12,35 12,35 C12,35 9,34 7,32   C5,31 4,30 4,30    C4,30 6,31 7,32    C10,34 12,35 12,35 C13,34 13,33 13,32 C13,29 14,26 14,24 C14,24 14,24 14,24 C14,24 9,16 9,16   C9,16 6,10 6,10    C6,10 8,13 10,16   C12,20 15,23 15,23 C16,20 17,19 17,19 C17,19 15,16 16,14 C17,13 20,12 21,12 C21,11 21,11 22,10 C22,10 25,10 25,10 C25,11 26,12 27,12 C28,11 31,13 31,14 z


// SmartIpm.MoscaWidget = SmartIpm.AbstractWidget.extend({
// });
  function post_init() {
    var self=this;
    self.diametro=0;
    self.width=0;
    var or=0;
    self.rect_h=50;
    self.reset_var=false;
    if(typeof self.model_name === 'undefined'){
      self.model_name='olive_bactrocera';
    }

    if(self.model_name=='olive_bactrocera'){
      self.table_formatter=[
        {'field':'datetime','label':t('Data')},
        {'field':'eggs','label':t('Eggs')},
        {'field':'larva','label':t('Larva')},
        {'field':'pupa','label':t('Pupa')},
        {'field':'adult','label':t('Adult')},
      ];
    }

    if(self.model_name=='olive_bactrocera_multi'){
      self.table_formatter=[
        {'field':'datetime','label':t('Data')},
        {'field':function(v){
          //  console.log(v);
            return ((v.E1+v.E2+v.E3)).toFixed(1);
         } ,'label':t(' Eggs')},
         {'field':function(v){
            // console.log(v);
             return (v.L1+v.L2+v.L3).toFixed(1);
          } ,'label':t('Larvae')},
          {'field':function(v){
            //  console.log(v);
              return ((v.P1+v.P2+v.P3)).toFixed(1)+"";
           } ,'label':t(' Pupae')},
        {'field':function(v){
          //  console.log(v);
            return (v.A1+v.A2+v.A3).toFixed(1);
         } ,'label':t(' Adult')}
      ];
    }

    if(self.model_name=='grape_lobesia'){
      self.table_formatter=[
        {'field':'datetime','label':t('Data')},
        {'field':function(v){
            var res=self.LobesiaDss(v);
            return res.message;
         } ,'label':t(' Stato')},
        {'field':function(v){
          //  console.log(v);
            return ((v.U1+v.U2+v.U3)).toFixed(1);
         } ,'label':t(' Eggs')},
         {'field':function(v){
            // console.log(v);
             return (v.L1+v.L2+v.L3).toFixed(1);
          } ,'label':t('Larvae')},
          {'field':function(v){
            //  console.log(v);
              return ((v.C0+v.C1+v.C2+v.C3)).toFixed(1)+"";
           } ,'label':t('Crysalids')},
        {'field':function(v){
          //  console.log(v);
            return (v.A1+v.A2+v.A3).toFixed(1);
         } ,'label':t(' Adult')}
      ];
    }

      // {'label:"Eggs"', 'calculate_field': function(d){
      //   return d['U1']+d['U2']+d['U3'];
      // }}
    // ];
  }
  function LobesiaDss(v){

    var self=this;

    var message="";
    var valMod=[v.C0,v.A1,v.U1,v.L1,v.C1,v.A2,v.U2,v.L2,v.C2,v.A3,v.U3,v.L3,v.C3];
    var lab=["Crisalidi", "Adulti", "Uova", "Larve","Crisalidi", "Adulti", "Uova", "Larve","Crisalidi", "Adulti", "Uova", "Larve","Crisalidi" ];
    var maxPos=-1;
    var maxVal=-999;
    for(var a=0; a<valMod.length; a++){
      var val=valMod[a];
      if(val>maxVal){
          maxPos=a;
          maxVal=val;
      }
    }

    message+="|"+maxPos+"|";
    if(maxPos===0){
        message="Fase prevalente: Crisalidi svernanti.";
        if(valMod[maxPos]>=0.99)
            message+=" Sfarfallamenti degli adulti della prima generazione non iniziati.";
        else if(valMod[maxPos]>=0.95){
            message+=" Si sta approssimando l'inizio degli sfarfallamenti degli adulti della prima generazione. ";
        }
        else{
            message=" Fase: Inizio sfarfallamenti I generazione. Il "+convLib.getPerc(valMod[maxPos+1])+" degli adulti risulta sfarfallato. ";
        }
    }
    else{
      var gen="";
      var fase=maxPos;
      if(maxPos<=4){
          gen="I";
      }
      else if(maxPos<=8){
          gen="II";
          fase=maxPos-4;
      }
      else{
          gen="III";
          fase=maxPos-8;
      }

      if(fase==1){
          message="Fase prevalente: Adulti "+gen+" generazione.";
          if(valMod[maxPos+1]>=0.02){
              message+=" Il "+self.getPerc(valMod[maxPos+1])+" degli adulti ha ovideposto. ";
          }
          else if(valMod[maxPos+2]>0.02){
              message+=" Attenzione! Inizio schiusura uova.";
          }
      }
      else if(fase==2){
          message="Fase prevalente: Ovideposizione "+gen+" generazione.";
          if(valMod[maxPos+1]>0.02){
              message+=" Attenzione! Il "+self.getPerc(valMod[maxPos+1])+" delle uova ha schiuso.";
          }
      }
      else if(fase==3){
          message="Fase prevalente: Larve "+gen+" generazione.";
          if(valMod[maxPos+1]>0.02){
              message+="Il "+self.getPerc(valMod[maxPos+1])+" delle larve si e' incrisalidata.";
          }
          else if(valMod[maxPos-1]>0.05){
              message+="Il "+self.getPerc(valMod[maxPos-1])+" delle uova non hanno ancora schiuso.";
          }
          else
              message+="Schiusura delle uova completata. ";
      }
      else if(fase==4){
          message="Fase prevalente: Crisalidi "+gen+" generazione.";
          try{
              if(valMod[maxPos+1]>0.02){
                  message+="Il "+self.getPerc(valMod[maxPos+1])+" delle crisalidi e' sfarfallata.";
              }
          }
          catch(e){message="";}
      }
      else{
          message="Sviluppo completato della III generazione.";
      }
  }

    return {"message": message};

  }
  function getPerc (data){
    return data.toFixed(0)+"%";
  }
  function prepareData (data, opt){
    return data;
  }
  function drawArc (div, data,opt){
    // console.log(div);
    var self=this;
    var scale_font_size = d3.scaleLinear()
        .domain([100,900,4000])
        .range([14,35,36]);

    // var font_size=scale_font_size(jQuery("#"+div).width());
    var font_size=21;
    var width = (jQuery("#"+div).width()-10);

    var height;
    var ir;

    var w = width;
    if(w<300){
      w=300;
    }
    var h = (parseFloat(350)-41-50-20-10);
    // console.log(h);
    var larghezza_arco=30;
    var spazio_tra_archi=5;

    var diametro=(2*h);

    // console.log("wid:"+width);
    // console.log("dia:"+diametro);
    // console.log(opt.dialog);
    height = 0;
    if (typeof opt.dialog=='undefined' && diametro>width){
      var or;
      if (window.innerWidth<441) {
        h=w/2;
        or = h-10;
      }
      else {
        h= 169;
        or = 159;
      }

      // console.log(self.width);
      // console.log(self.or);
      // console.log("0l0l0l0l0l");
    }
    else {
      // console.log(opt.dialog);
      h= 169;
      // console.log(self.width);
      // self.or = h-10;
      or = 159;
      // if( typeof opt.dialog !== 'undefined' && opt.dialog ){
      //   self.or = w/2.6;
      //   if( window.innerWidth > 1800) {
      //     height = 11;
      //   }
      //   else if(window.innerWidth > 1000 && window.innerWidth < 1600 ) {
      //     height = -24;
      //   }
      // }
      // else {
      //   // or = w/2.5;
      //   self.or = h-10;
      //   larghezza_arco=35;
      //   spazio_tra_archi=5;
      // }
    }

    ir = or-larghezza_arco;

    var label="";

    if(opt && opt.stages_label){
      label=opt.stages_label;
    }

    var generazione="";
    if(opt){
      generazione=opt.generation;
    }

    var svg = d3.select("#"+div+"_mosca_arc")
        // .append("svg")
        .attr("width", w)
        .attr("height", h+height)
        .append("g")
        .attr("transform", "translate("+w/2+","+((h/2)+(or/2))+")");


     svg.append("text")
      .text(function() {if (generazione!==""){ return t("Generation")+": "+generazione;}})
      // .text("<span id='btn_legend'> <span class='glyphicon glyphicon-th-list'></span> </span>")
      .attr("font-family", "sans-serif")
      .attr("font-size", font_size)
      .attr("text-anchor", "middle")
      .attr("x", "0")
      .attr("y", -10-font_size);

      svg.append("text")
       .text(function() {if (label !== ""){ return t("Stage")+":"+label;}})
       .attr("font-family", "sans-serif")
       .attr("font-size", font_size)
       .attr("text-anchor", "middle")
       .attr("x", "0")
       .attr("y", "-10");


    //Versio  ne funzionante
    jQuery.each(data, function(k,v){
      if( k > 0 ) {
        or -= larghezza_arco+spazio_tra_archi;
        ir -= larghezza_arco+spazio_tra_archi;
      }
      var setColor = "steelblue";


      var scale = d3.scaleLinear()
          .domain([0,100])
          .range([-90*Math.PI/180,90*Math.PI/180]);

      var arc = d3.arc()
          .innerRadius(ir)
          .outerRadius(or);

        var reduction=0;
        if(typeof v.reduction!=='undefined'){
          reduction=v.reduction;
        }
        var arc_value=scale(v.scale(v.value)-reduction);
        //console.log("ARCVALUE "+k+" - "+v.value+" "+v.scale(v.value)+" "+arc_value);

        svg.append("g")
          .append("path")
          .attr("class", "arc"+k)
          .transition().duration(0)
          .attrTween("d", function (d) {
              var start = {startAngle: scale(0), endAngle: scale(100)};
              var arrivo={startAngle: scale(0), endAngle: arc_value };
              var interpolate = d3.interpolate(start, arrivo); // <-B
              return function (t) {
                  return arc(interpolate(t)); // <-C
              };
          })
          .style('fill','white');

          svg.append("g")
            .append("path")
            .attr("class", "arc_bg")
            .transition().duration(0)
            .attrTween("d", function (d) {
                var start = {startAngle: scale(0), endAngle: scale(100)};
                var arrivo={startAngle: arc_value, endAngle: scale(100)};
                var interpolate = d3.interpolate(start, arrivo); // <-B
                return function (t) {
                    return arc(interpolate(t)); // <-C
                };
            });

            var pieGroup = svg.selectAll(".arc_legend_"+k)
            .data(v.scale.domain())
            .enter().append("path")
            .attr("class", "arc_legend_"+k)
            .attr("id", function(d,i){return div+"arc_"+k+"_"+i;})
            .attr('fill', 'transparent')
            .attr('stroke', 'black')
            .attr('fill-opacity', '0.1')
            .attr("d",function(d,i){

              var start=v.scale(v.scale.domain()[i-1]);
              var end=v.scale(d);
              if(i===0){
                start=0;
              }
              if(typeof v.scale.domain()[i+1]==="undefined"){
                end=100;
              }
              // console.log(v.substages_label[i]+"("+i+") va da "+start+" a "+end+"|"+v.scale.domain()[i+1]);

              return arc({startAngle: scale(start), endAngle:scale(end) });
            });


            svg .selectAll(".arc_label_"+k)
            .data(v.scale.domain())
            .enter().append("text")
            .attr("dy",(larghezza_arco/2+5)+"px")
            .attr("class", "arc_label_"+k)
            .append("textPath") //append a textPath to the text element
          	.attr("xlink:href", function(d,i){
              return "#"+div+"arc_"+k+"_"+i;
            })
          	.style("text-anchor","middle") //place the text halfway on the arc
          	.attr("startOffset", "20%")
          	.text(function(d,i){
              try{
                return v.substages_label[i];
              }
              catch(e){return "";}
            });

      d3.selectAll('.arc'+k).style("fill","white");
      d3.selectAll('.arc_bg').style("fill","#ededed").style("opacity","0.5");
    });
}
  export function drawSummary (model_name, div, data, opt){
    var soglia=1;
    var self=this;
    var stages=[];
    var stage_code=[];
    var substages_label_short;
    var substages_label;

    var change_date=false;
    var start_date='07-15';
    if(model_name=='helicoverpa'){
      stages=['adult_emergence','adult_preovi','adult_ovi','egg','larvae','pupae','adult_emergence2'];
    }
    else if(model_name.indexOf('grape_lobesia')){
      stages=['C0', 'A1', 'U1', 'L1', 'C1', 'A2', 'U2', 'L2', 'C2', 'A3', 'U3', 'L3', 'C3'];
      stage_code=["A","U","L","C"];
      substages_label_short=["Adu","Egg","Lar","Chr"];
      substages_label=[t("Adult"),t("Eggs"),t("Larva"),t("Chrysalis")];
    }
    else if(model_name=='olive_bactrocera'){
      stages=["E","L","P","A"];
      substages_label_short=["Egg","Lar","Pup","Adu"];
      substages_label=[t("Eggs"),t("Larva"),t("Pupae"),t("Adult")];
    }
    else if(model_name=='olive_bactrocera_multi'){
      stages=['E1', 'L1', 'P1', 'A1', 'E2', 'L2', 'P2', 'A2', 'E3', 'L3', 'P3', 'A3' ];
      stage_code=["E","L","P","A"];
      substages_label_short=["Egg","Lar","Pup","Adu"];
      substages_label=[t("Eggs"),t("Larva"),t("Pupae"),t("Adult")];
      change_date=true;
      start_date='07-15';
    }
    if(typeof substages_label=='undefined'){
      substages_label=stages;
    }

    var currentYear=new Date().getFullYear();

    var html="";
    var max='A4';
    var max_value=100;

    var stages_rect=[];

    var model_not_started=false;
    var msg_start='';
    if(change_date){
      var last_weather_date=data.final_value.datetime.substring(5, 14);
      var first_model_date=start_date;
      if(typeof self.settings.olive_fruit_fly!=='undefined'){
        first_model_date=self.settings.olive_fruit_fly.start_egg.substring(5, 14);
      }
      //msg_start=" last weather "+last_weather_date+" should be more then start model "+first_model_date+": "+(last_weather_date<=first_model_date);
      if(last_weather_date<=first_model_date){
        model_not_started=true;
      }

    }

    // console.log(data.final_value.datetime);
    if (model_not_started) {
      var msg=(""+t("The model has not started yet")+" - "+msg_start+"");
      var sub="";
      if(change_date){
        var val=currentYear+'-'+start_date;
        if(typeof self.settings.olive_fruit_fly!=='undefined'){
          val=self.settings.olive_fruit_fly.start_egg;
        }
        sub=('<div class="col-xs-12"><label for="update_fly_date">'+t("Start of ovideposition")+'</label><input type="date" id="update_fly_date" class="form-control" value="'+val+'"></div>');
      }
      setContent(div,opt, {'main':"", 'sub':msg+sub, 'pictogram':'' , 'alert_level':'info'});

    }
    else {

      jQuery.each(stages, function(k,v){
        if(data.final_value[v]>soglia){
          stages_rect.push({'label':v, 'value':data.final_value[v]});
          html+="<li>"+v+": "+data.final_value[v];
          max=v;
          max_value=parseFloat(data.final_value[v]);
        }
      });

      //fai la somma di Value
      //crea un rettangolo, dividi il rettangolo in sezioni porporzionali a value (fai prima la somma)
      //aggoingi etichetta
      html+="Ultimo stadio: |"+max+"|<br/>";
      html+="Value di stadio: <b>"+max_value+"</b><br/>";

      var generazione= max.substring(1, 2);
      var stadio= max.substring(0, 1);
      self.stadio=stadio;
      var scale_stadio = d3.scaleOrdinal()
         .domain(stage_code)
         .range([25,40,60,100]);

      var scale_gen = d3.scaleOrdinal()
         .domain([1,2,3,4])
         .range([25,50,75,100]);

      var index_prev=stage_code.indexOf(stadio)-1;
      var lim_min=0;

      if(index_prev>=0){
        lim_min=parseFloat(scale_stadio(stage_code[index_prev]));
      }
      var lim_max=parseFloat(scale_stadio(stadio));

      var scale_reduction=d3.scaleLinear()
          .domain([100,0])
          .range([0, (lim_max-lim_min)*0.8]);
      var reduction=scale_reduction(max_value);

      var delta_gen=25;
      var scale_reduction_generazione=d3.scaleOrdinal()
          .domain(stage_code)
          .range([(delta_gen*0.75),(delta_gen*0.5),(delta_gen*0.25), (delta_gen*0)]);
      var gen_reduction=scale_reduction_generazione(stadio);

      html+="Limite Minimo: ("+stadio+")"+lim_min;
      html+="Limite Massimo: ("+stadio+")"+lim_max;
      html+="reduction: ("+max_value+")"+reduction;

      // jQuery(div).append(html);
      //var num_arc=[100*(generazione/4),scale_stadio(stadio)];



      var num_arc=[
        {
            "value":generazione, "label":"Generazione", "scale":scale_gen,
            "color":"#607D8B", "substages_label":["1gen","2gen","3gen","4gen"], 'reduction':gen_reduction},
        {
          "value":stadio, "label":"Stadio", "scale":scale_stadio,
          "color":"#8BC34A","substages_label":substages_label_short,
          "reduction":reduction
        }
      ];

      var scale_label= d3.scaleOrdinal()
         .domain(stage_code)
         .range(substages_label);
      opt.generation=generazione;
      opt.stages_label=scale_label(stadio);

      if (div[0]=='#') {
        div=div.substring(1);
      }

      jQuery("#"+div+" .sipm_pictogram").css('display','block');
      jQuery("#"+div+" .sipm_pictogram").css('overflow','hidden');
      // jQuery("#"+self.div+" .sipm_pictogram").height(170);
      // jQuery("#"+self.div+" .card-content").height(25);
      var sub2="";

      if(change_date){
        var val2=currentYear+'-'+start_date;
        if(typeof self.settings.olive_fruit_fly!=='undefined'){
          val2=self.settings.olive_fruit_fly.start_egg;
        }
        sub2=('<div class="col-xs-12"><label for="update_fly_date">'+t("Start of ovideposition")+'</label><input type="date" id="update_fly_date" class="form-control" value="'+val2+'"></div>');
      }

      var main="";
      if(self.model_name=='grape_lobesia'){
        var ll=self.LobesiaDss(data.final_value);
        main=ll.message;
      }

      var pictogram="<svg id='"+div+"_mosca_arc'></svg>";
      // if (theme) {
      // }
      // jQuery('#'+div).html(pictogram);
      setContent(div,opt, {'main':main, 'sub':sub2, 'pictogram':pictogram , 'alert_level':'info'});


      drawArc(div,num_arc,opt);
      //self.drawRect(div,stages_rect,opt);

      jQuery('#modal-body_summary').css('padding-top',34);//altezza del bottone


    }


    if(change_date){

      // var but_pre=jQuery("#"+self.div+" .card-footer").html();
      var button='<button class="btn btn-default btn-sm" type="submit" id="update_fly_but">'+t("Save data")+'</button>';
      // jQuery("#"+self.div+" .card-footer").html(button);
      jQuery("#"+self.div+" #update_fly_date").change(function(){
        console.log("change");
        var settings={
          'olive_fruit_fly':{
            'start_egg':jQuery("#"+self.div+" #update_fly_date").val()
          }
        };
        //Save the Data
        if(typeof self.opt.dashboard!=='undefined'){
          self.opt.dashboard.saveAndUpdateDashboard(settings,['olive_bactrocera_multi']);
        }
        else {
          var runModelSettings = jQuery.extend({}, self.settings, settings);

          // console.log(self.settings);
          // var runModelSettings=self.settings;
          // runModelSettings.param.date_from=jQuery("#"+self.div+" #update_fly_date").val();
          console.log(runModelSettings);
          self.runModel([],runModelSettings);
        }
      });
    }
  }
  function drawRect(div, data, opt){
    //fai la somma di Value
    //
    //crea un rettangolo, dividi il rettangolo in sezioni porporzionali a value (fai prima la somma)
    //aggoingi etichetta
    var self=this;
    var height= (parseFloat(jQuery('.sipm_widget').css('min-height'))-41-50-20);
    var rect_padding;

    if (typeof opt.dialog=='undefined' && self.diametro>self.width){
      rect_padding=10;
    }
    else {
      if( opt.dialog ){
        rect_padding=	(jQuery(div).width())/2-(jQuery(div).width())/2.6;
      }
      else {
        rect_padding=(((jQuery(div).width())-(or*2+70+10))/2)-5;
      }
    }
    var tot_sum=0;

    jQuery.each(data, function(k, v) {
      v.start=tot_sum;
      v.end=tot_sum+v.value;
      tot_sum += v.value;
    });


    var w = jQuery("#"+div).width()-10;
    if(w<300){
      w=300;
    }
     var rect_h=45;
    var lunghezza_rect;
    if (typeof opt.dialog=='undefined' && self.diametro>self.width){
      lunghezza_rect = w-2*10;
      // console.log(lunghezza_rect);
      // console.log(w);
      // console.log("NO DIALOG NO DIAM");
    }
    else {
      if( opt.dialog ){
        // console.log("SI DIALOG NO DIAM");
        lunghezza_rect = w/2.6*2;
      }
      else {
        // console.log("NO DIALOG SI DIAM");
        lunghezza_rect =(or*2+70+10);
      }
    }

    var scale_rect = d3.scaleLinear()
        .domain([0,tot_sum])
        .range([0,lunghezza_rect]);


    var chart = d3.select(""+div+'_mosca_rect')//.append("svg")
      .attr("width", w)
      .attr("height", 57)
      .attr("transform", "translate("+w/2+","+((rect_h/2)+((w/2.3)/2))+")");

    var dataset2 = [0];
    var label="";
    jQuery.each(data, function(k,v) {
      label=v.label;
      chart.append("text")
        .text(v.label)
        .attr("font-family", "sans-serif")
        .attr("text-anchor", "middle")
        .attr("x", ((scale_rect(v.start)+scale_rect(v.end))/2)+rect_padding)
        .attr("y", 57)
        .attr("fill", "black");
    });

    var rects_bg = chart
      .append("rect")
      .attr('class', 'rect_bg')
      .attr("x", rect_padding)
      .attr("y", 0)
      .attr("width", lunghezza_rect)
      .attr("height", rect_h)
      .attr("fill", "#ddd");

    chart.selectAll(".rect_border")
    .data(data)
    .enter().append("rect")
    .attr("class",function(d,i){return "rect_border "+(d.label).substring(0,1);})
    .attr("x", function(d,i){return (scale_rect(d.start)+rect_padding);})
    .attr("y", 0)
    .attr("width", function(d,i){return scale_rect(d.end-d.start);})
    .attr("height", rect_h)
    .attr("stroke", "black")
    .attr("fill", function(d,i){
      if ((d.label).substring(0,1)!=="E") {
        return "transparent";
      }
      else {
        return "#EF5350";
      }
    });


  }

  export function drawChart(model_name, div, data, opt){
    console.log(data);
    var self=this;
    self.chart_div=div;


    jQuery(div).html('');
    if(self.model_name!='olive_bactrocera_multi'){
      jQuery(div).append('<button class="btn btn-info" id="update">Update</button>');
      jQuery(div).append('<button class="btn btn-info" id="reset">Reset</button>');


      jQuery(div+" #update").click(function(){
        self.runModel(self.current_weather,self.getSettings());
      });

      jQuery(div+"_result #reset").click(function(){
         self.reset_var=true;
         self.runModel(self.current_weather,self.getSettings());
      });
    }
    var model_result=data;
    drawChartCustom(model_name, div, model_result, {'div':div, 'label_date':'datetime', 'action': self.action});
    // jQuery(div).css("padding-top",(jQuery(div).height()*0.3));

  }
  function drawChartCustom(model_name, div, data,opt){
    console.log(data);
    // var self = this;
    // //etichetta del campo data del modello
    // var label_date='data';
    // if(opt.label_date){
    //   label_date=opt.label_date;
    // }
    //
    // var width=jQuery(div).width();
    // var height=jQuery(div).height();
    // if(width<300){
    //   width=jQuery(div).width();
    // }
    // if(height<300){
    //   height=300;
    // }
    //
    // jQuery(div).append('<div class="top"></div>');
    // jQuery(div).append('<div class="bottom"></div>');
    //
    // //calcolo gli array per le dati e per gli stadi
    // function getData(d){
    //   return d[label_date];
    // }
    // var date=d3.map(data,getData).keys()
    //   .map(function(d){return{'date':d,'eggs':0};});
    //
    // //aggiungi le uova all'array data
    // if(opt.action ){
    //   jQuery.each(opt.action, function(k,v){
    //     jQuery.each(date, function(k2,v2){
    //       if(v2.date==v.datetime){
    //         v2.eggs=v.add;
    //       }
    //     });
    //   });
    // }
    // var stages=['eggs','larva','pupa','adult'];
    // //var stages=['E','L','P','A'];
    // var check_date=function(d){return true;};
    //
    // if(model_name=='helicoverpa'){
    //   stages=['adult_emergence','adult_preovi','adult_ovi','egg','larvae','pupae','adult_emergence2'];
    // }
    // else if(model_name=='grape_lobesia' || model_name=='olive_bactrocera_multi'){
      //Prepare the data for lobesia (group together the generations)
      var stages=['C','A','U','L'];
      var C='C';
      var vv=['A','L','U'];
      // if(model_name=='olive_bactrocera_multi'){
      //   stages=['E','L','P','A'];
      //   vv=stages;
      //   check_date=function(d){
      //     return d.date.substr(5,5)>'06-14' && d.date.substr(5,5)<'10-15';
      //   };
      // }


      var data2=[];
      jQuery.each(data, function(k,v){
        // debugger
        var rec={};
        rec['datetime']=v['datetime'];
        rec[C]=v[C+'0']+v[C+'1']+v[C+'2']+v[C+'3'];
        // console.log(rec[C]+" "+v[C+'0']+" "+v[C+'1']+" "+v[C+'2']+" "+v[C+'3']);
        for (var i=0; i<4; i++){
          var stage=vv[i];
          rec[stage]=v[stage+'1']+v[stage+'2']+v[stage+'3'];
        }
        data2.push(rec);
      });
      data=data2;
      // debugger
    // }
    //
    // //Trasforma i dati in un array pronto per disegnare i rettangoli
    // var rect_array=[];
    //
    // for (var d=0; d<date.length; d++){
    //   var date_ok=check_date(date[d]);
    //   // debugger
    //   if(date_ok){
    //     for (var s=0; s<stages.length; s++){
    //       var array={'d':d, 's':s, 'stage': stages[s], 'date':new Date(date[d].date) };
    //       jQuery.each(data, function(k,v){
    //         if(v[label_date]==date[d].date){
    //           array.val=v[stages[s]];
    //         }
    //       });
    //       rect_array.push(array);
    //     }
    //   }
    // }
    //
    // console.log("rect_array");
    // console.log(rect_array);

    // var g=new D3Chart(rect_array, {
    //   'div':div+' .top',
    //   'type':'line',
    //   'dim_x': function(d,i) {return new Date(d.date).getTime();},
    //   'dim_y': function(d,i) {if(d.val!==null){return parseFloat(d.val);}},
    //   'dim_z': function(d) {return d.stage;},
    //   'legend':true,
    //   'zoom': true,
    //   'axis_x': {
    //               mode: "time",
    //               label:"Data"
    //             },
    //   'axis_y': {
    //               //min:0,
    //               label:t("Value")
    //             },
    //   'interpolate': d3.curveLinear
    // });
    //
    // //prendo dal grafico la scala delle x per poter posizionare i quadrati
    // var scale_x=g.axis.x.scale();
    // var padding=g.padding;
    // //le dimensioni del riquadro
    // var square_size=20;
    //
    //
    // //creo l'SVG
    // var svg = d3.select(""+div+'_mosca_rect')//.append("svg")
    //   .attr("width", width)
    //   .attr("height", ((square_size+10)+(square_size*stages.length)));
    //
    // svg.append("defs").append("clipPath")
    //   .attr("id", div+"_clip2")
    //   .append("rect")
    //   .attr("width", (width-2*padding))
    //   .attr("height", ((square_size+10)+(square_size*(stages.length))));
    //
    //
    //
    // //si crea un g che contiene i rettangoli di enter con un g interno zoomabile
    // var grafica_enter=
    //   svg.append("g")
    //   .attr("class","enter_container")
    //   .attr("transform", 'translate('+padding+',0)')
    //   .attr("clip-path", "url("+div+"_clip2)")
    //   .append("g")
    //   .attr("class", "zoomable");
    //
    // //si crea un g che contiene i rettangoli di unitarray con un g interno zoomabile
    // var grafica=
    //   svg.append("g")
    //   .attr("class","rect_container")
    //   .attr("transform", 'translate('+padding+','+(square_size+10)+')')
    //   .attr("clip-path", "url("+div+"_clip2)")
    //   .append("g")
    //   .attr("class", "zoomable");
    //
    // var grafica_label=
    //   svg.append("g")
    //   .attr("class","label_container")
    //   .attr("transform", 'translate('+padding+','+(square_size+10)+')');
    //
    // var input_label=
    //   svg.append("g")
    //   .attr("class","input_container");
    //
    // //creo il tip
    // var tip = d3.tip()
    //   .attr('class', 'd3-tip')
    //   .offset([-10, 0])
    //   .html(function(d) {
    //     return stages[d.s].toUpperCase()+": "+self.toFix(d.val)+"<br> DATA:"+d.date;
    // });
    //
    // var min=d3.min(rect_array,function(d){return parseFloat(d.val);});
    // var max=d3.max(rect_array,function(d){return parseFloat(d.val);});
    //
    // var scale = d3.scaleLinear();
    //
    // if(max>100){
    //   scale.domain([min, 0.1, 20, 30, 50, max])
    //   .range(["#ccc","#ffffd4", "#fee391", "#fec44f", "#fe9929", "#d95f0e"]);
    // }
    // else {
    //   scale.domain([0, 0.1, 20, 30, 50, 100])
    //   .range(["#ccc","#ffffd4", "#fee391", "#fec44f", "#fe9929", "#d95f0e"]);
    // }
    // var larghezza=(g.innerWidth/d);
    //
    // var scaleAdd = d3.scaleLinear()
    //   .domain([0, 101])
    //   .range(["#ddd", "#F00"]);
    //
    // var rect_enter = grafica_enter.selectAll("rect")
    //   .data(date)
    //   .enter()
    //   .append("rect")
    //   .attr("width",larghezza)
    //   .attr("height",square_size)
    //   .attr("x", function(d,i){return (scale_x(new Date(d.date)));})
    //   .attr("y",  0*square_size)
    //   .style('stroke','#999')
    //   .style('stroke-width','0')
    //   .style('fill', function(d){
    //     return scaleAdd(d.eggs);
    //   })
    //   .on('click', function(d){
    //     var rect=(d3.select(this));
    //     d.eggs=d.eggs+25;
    //     if(d.eggs>100)
    //       d.eggs=0;
    //     rect.data(d);
    //     rect.style('fill',scaleAdd(d.eggs));
    //   });
    //
    // var rect = grafica.selectAll("rect")
    //   .data(rect_array)
    //   .enter().append("rect")
    //   .attr("x", function(d,i){return (scale_x(d.date)-1);})
    //   .attr("y", function(d,i){return (d.s)*square_size;})
    //   .attr("width",larghezza+1)
    //   .attr("height",square_size)
    //   .style('fill', function(d){return scale(self.toFix(d.val));})
    //   .style("stroke-width",'0')
    //   //.style('stroke','#FFF')
    //   .on('mouseover', tip.show)
    //   .on('mouseout', tip.hide);
    //
    // rect.call(tip);
    //
    // grafica_label.selectAll("text")
    //   .data(stages)
    //   .enter()
    //   .append("text")
    //   .text(function(d) {return d.toUpperCase();})
    //   .on('click', function(d, i){
    //     d3.selectAll(''+div+' .label_container text').attr('font-weight','normal');
    //     d3.select(this).attr('font-weight','bold');
    //
    //     d3.map(stages,function(d) {
    //       d3.selectAll(''+div+' .cl_'+d+' path').transition().duration(1000).style('opacity',0).style('stroke-width','4');
    //     });
    //     d3.selectAll(''+div+' .cl_'+d+' path').transition().duration(1000).style('opacity',1).style('stroke-width','4');
    //   })
    //   //  .attr("font-family", "sans-serif")
    //   //  .attr("text-anchor", "middle")
    //   .attr("font-size", "11px")
    //   .attr("fill", "black")
    //   .attr("x", -padding)
    //   .attr("y", function(d,i){return (i+0.7)*square_size;})
    //   // .transition().duration(500)
    //   // .attr("x", -padding)
    //   .each(function(d) { var bbox = this.getBBox();
    //     grafica_label.insert("rect", "text")
    //       .style("fill", "#fff")
    //       .attr("x", bbox.x)
    //       .attr("y", bbox.y)
    //       .attr("width", bbox.width)
    //       .attr("height", bbox.height);
    //   });
    //
    //
    //
    //   var input=["input"];
    //
    //   input_label.selectAll("text")
    //     .data(input)
    //     .enter()
    //     .append("text")
    //     .text(function(d) {return d.toUpperCase();})
    //     .attr("font-size", "11px")
    //     .attr("fill", "black")
    //     .attr("x", -90)
    //     .attr("y", function(d,i){return (i+0.7)*square_size;})
    //     .transition().duration(500)
    //     .attr("x",0);
    //
    //
    // var chart_zoom=g.zoomBeh.on('zoom');
    // g.zoomBeh.on("zoom", function()  {
    //   chart_zoom();
    //   scale=d3.event.transform.k;
    //   var translate_x=d3.event.transform.x;
    //
    //   d3.selectAll(''+div+' .bottom .zoomable').attr("transform", 'translate('+translate_x+',0)scale(' + scale + ',1)');
    // });

    // debugger
    var chart = c3.generate({
      bindto: '#'+div,
      padding: {
        right: 40,
      },
      data: {
        x:'datetime',
        json:data,
        keys:{
          value:['datetime','A','C','L','U']
        },
        names: {
          A: t('Adulti'),
          C: t('Crisalidi'),
          L: t('Larve'),
          U: t('Uova')
        },
        axes: {
          deficit: 'y',
          opt_thr_mm: 'y',
          cri_thr_mm: 'y',
          rain: 'y'
        },
        colors: {
          A: '#1a237e',
          C: '#DA3C3D',
          L: '#009688',
          U: '#ffa000',
        },
        type:'spline',
        types:{
          rain:'bar',
          water_applied_auto:'bar',
          water_applied_farmer:'bar'
        }
      },
      point: {
       show: false
      },
      subchart: {
        show: false
      },
      axis : {
        x : {
          type : 'timeseries',
          tick: {
            count:5,
            format: function (x) { return (x.getMonth()+1)+'-'+x.getFullYear(); }
            //format: '%Y' // format string is also available for timeseries data
          }
        },
        y:{
          max:100
          // label: '(mm)'
        },
        // y2: {
        //   show: true,
        //   label: 'Pioggia (mm)'
        // }
      }
    });

  }
  function getSettings(){
    var self=this;
    self.action=[];
    d3.selectAll(''+self.chart_div+' .enter_container rect').each(function(d){
      var rect=d3.select(this);
      if (self.reset_var===false) {
        if(d.eggs>0){
          self.action.push({'datetime':d.date, 'add':d.eggs});
        }
      }
    });
    self.reset_var=false;
    return {"action":self.action};
  }
  function toFix(num,dec){
    if(typeof num != "undefined"){
      if( typeof dec === 'undefined'){
        dec = 2;
      }
      return (num).toFixed(dec);
    }
    else{
      return "-";
    }
  }
  export function setContent(div,opt, content){
    //jQuery("#"+this.div+" .sipm_pictogram").append(
    // var self=this;
    var html='';
    html+='<div class="sipm_pictogram">'+content.pictogram+'</div>';
    html+='<div class="sipm_summary_title">'+content.main+'</div>';
    html+='<div class="sipm_summary_sub">'+content.sub+'</div>';
    jQuery('#'+div).html(html);

    // debugger
    // jQuery('#'+div+' .sipm_pictogram').html(content.pictogram);
    // jQuery('#'+div+' .sipm_main').html(content.main);
    // jQuery('#'+div+' .sipm_sub').html(content.sub);

    if(typeof(content.alert_level)!=='undefined'){
      if(jQuery('#'+div+" ").length>0){
        jQuery.each(jQuery('#'+div+" ")[0].className.split(/\s+/),function(k,cl){
            if(cl.startsWith('alert_level_')){
              jQuery('#'+div+" ").removeClass(cl);
            }
          });
        jQuery('#'+div+" ").addClass('alert_level_'+content.alert_level);
      }
    }

    if(opt.layout=='complete'){
      jQuery("#"+div+" .card-content").css('overflow','auto');
      jQuery("#"+div+" .card-content").css('height','auto');
    }
  }


import global from '../../global.js';
import * as tools from '../../tools.js';
import * as db from './data.js';
import mapHelper from '../../core/map.js';
import FabComponent from 'framework7/components/fab/fab';
import t from '../../translate.js';


export function home_explorer(){
  jQuery('#footer').html('');
  var map=new mapHelper();


  jQuery("#f7_content").css("overflow","hidden");


  global.theme.render({
    "title": "Poderi Explorer",
    "content": `

    <div class="row">
    <!-- Each "cell" has col-[width in percents] class -->
      <div class="col-50">
        ${map.getHtml(false,false)}
      </div>
      <div id="right_pane" style="overflow:auto;" class="col-50">


        <div id="message_space">
        </div>
        <div id="filter" class="row">
        <div class="col-33">
        <div class="list">
        <h5>Tipo di Report</h5>
            <ul>
              <li class="item-content item-input">
                <div class="item-input-wrap">
                  <select id="explore_type">
                    <option value='farm'>farm data</option>
                    <option value='nutrient'>nutrient</option>
                    <option value='water'>water</option>
                  </select>
                </div>
              </li>
            </ul>
            </div>
            </div>
            <div class="col-33">
            <div class="list">
            <h5>Stagione</h5>
            <ul>
              <li class="item-content item-input">
                <div class="item-input-wrap">
                  <select id="year">
                  </select>
                </div>
              </li>
            </ul>
            </div>
            </div>
            <div class="col-33">
          <div class="list">
          <h5>Coltura</h5>
          <ul>
                <li class="item-content item-input">
                  <div class="item-input-wrap">
                    <select id="id_crop">
                    </select>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card data-table">

          <table id="farm_list">
              <thead>
              </thead>
              <tbody id="soil_list"></tbody>
          </table>
        </div>
      </div>
  </div>


    `,
    exclude_card:true
  });

  map.fixMapHeight();

  jQuery("#right_pane").height(jQuery("#map_container").height());
  map.initMap([43,11],5, false, {});


  jQuery("#filter select").on("change", function(){

    explore(jQuery("#explore_type").val(), {
      "id_crop": jQuery("#id_crop").val(),
      "year": jQuery("#year").val()
    });

  }).trigger("change");


}



function explore(type, filter){


  var chat_url = global.chat_url;
  jQuery.ajax({
    contentType: "application/json; charset=utf-8",
    type: 'GET',
    url: chat_url+"api/v1/explorer/"+type,
    data:filter,
    dataType: "json",
    success: function(res){
      console.log(res);
      global.app.dialog.close();

      //jQuery("#message_space").html(JSON.stringify(res));

      //popola le tendine delle colture
      var co=`<option value="*">Tutte</option>`;
      jQuery.each(res.crop.data, function(kc,c){       
        co+=`<option  value="${c.id_crop}">${c.name}</option>`;

      });

      jQuery("#id_crop").html(co)
      if(filter.id_crop){
        jQuery("#id_crop").val(filter.id_crop);
      }

      //popola le tendine degli anni
      var cy=`<option value="*">Tutti</option>`;
      jQuery.each(res.year.data, function(ky,y){
        var s="";
        var current_year=(new Date()).toISOString().substring(0,4);
        //TODO fix anno hard-coded
        if(y==current_year){
          s=`selected="selected"`;

        }
        cy+=`<option ${s} value="${y.year}">${y.year}</option>`;

      });
      jQuery("#year").html(cy);
       
      if(filter.year){
        jQuery("#year").val(filter.year);
      }


      console.log(res);
      var feature_collection={
        "type": "FeatureCollection",
        "crs": {
          "type": "name",
          "properties": {
            "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
          }
        },
        "features": []
      };

      jQuery("#farm_list tbody").html("");

      if(type=='nutrient'){
        jQuery("#farm_list thead").html(`
          <tr><th rowspan=2>Azienda</th><th style="text-align:center" colspan="3">Somma</th><th style="text-align:center"  colspan="3">Media</th></tr>
          <tr><th>N</th><th>P</th><th>K</th><th>N</th><th>P</th><th>K</th></tr>
        `);
      }
      else if(type=='water'){
        jQuery("#farm_list thead").html(`<tr><th>Azienda</th><th>Mc Totali</th><th>mc/ha</th></tr>`);
      }
      else{
        jQuery("#farm_list thead").html(`<tr><th>Azienda</th><th>Superficie</th><th>Campi</th><th>Utenti</th></tr>`);
      }


      jQuery.each(res.data, function(kf,f){

        var tr="";

        if(type=='nutrient'){
          tr=jQuery(`<tr>
              <th>${f.name}</th>
              <td>${f.n_sum?f.n_sum.toFixed(1):"-"}</td>
              <td>${f.p_sum?f.p_sum.toFixed(1):"-"}</td>
              <td>${f.k_sum?f.k_sum.toFixed(1):"-"}</td>
              <td>${f.n_avg?f.n_avg.toFixed(1):"-"}</td>
              <td>${f.p_avg?f.p_avg.toFixed(1):"-"}</td>
              <td>${f.k_avg?f.k_avg.toFixed(1):"-"}</td>
            </tr>`)
            .appendTo("#farm_list tbody");
        }
        else if(type=='water'){
          tr=jQuery(`<tr>
              <th>${f.name}</th>
              <td>${f.water_sum_mc?f.water_sum_mc.toFixed(1):"-"}</td>
              <td>${f.water_ha_mc?f.water_ha_mc.toFixed(1):"-"}</td>
            </tr>`)
            .appendTo("#farm_list tbody");
        }
        else{
          tr=jQuery(`<tr>
              <th>${f.name}</th>
              <th>${f.sum_area?f.sum_area:"-"}</th>
              <th>${f.num_plot}</th>
              <td title="${f.users}">${f.users?f.users.length:0}</td>
            </tr>`)
            .appendTo("#farm_list tbody");
        }

          if(f.lat>0){
            feature_collection.features.push(
              {
                "type": "Feature",
                "properties": f,
                "geometry": {
                  "type": "Point",
                  "coordinates":  [f.lon, f.lat]
                },
              }
            );
          }
      });



      var geojsonLayer = L.geoJson(feature_collection, {
        onEachFeature: function (feature, layer) {

          layer.bindPopup(`
            <table class="table">
              <tbody>
                <tr><td>Nome</td><th>${feature.properties.name}</th></tr>
              </tbody>
            </table>
          `);
        }
      }).addTo(global.map);

      // location.hash='advice'
    },
    error: function(err){
      global.app.dialog.close();
      console.log(err.statusText);

      jQuery("#message_space").html(t("Attenzione, errore di connessione. Questa funzione richiede di avere una connessione internet attiva"))

    }
  });
}

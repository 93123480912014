/*jshint esversion: 6 */

import storage from "../../storage.js";
import global from '../../global.js';
// import * as partecipa_storage from '../partecipa_storage.js';

// import ae from "../../../global.js";
import t from "../../translate.js";

export default class Mosca {

  //Prima funzione
  constructor(id_survey_schema) {
    this.id_survey_schema=id_survey_schema;
  }

  //funzione di rendering; riceve come input il survey corrente
  render(current_survey){
    console.log(current_survey);

    var self=this;

    var body="";

    body+="<div>";
      body+="<div>";

        body+='<p class="row">';
          body+='<button puntura="no" class="col button button-fill button-large color-green btn-mosca mosca-no"><span>'+t("Puntura NO")+'</span></button>';
          body+='<button puntura="si" class="col button button-fill button-large color-red btn-mosca mosca-si"><span>'+t("Puntura SI")+'</span></button>';
        body+='</p>';

        var num_si=0;
        var num_no=0;
        var perc_infestazione=0;
        if(current_survey!=null){
          if(current_survey.num_si){
            num_si=current_survey.num_si;
          }
          if(current_survey.num_no){
            num_no=current_survey.num_no;
          }
        }

        var disabled='';
        if (global.nomeApp!=='ATLAS' && global.nomeApp!=='Poderi' && global.nomeApp!=='Agro Abruzzo') {
          disabled=' disabled ';
        }

        body+='<div class="row">';
            body+='<div class="col-50 text-center">';
              body+='<input class="col center puntura_input" id="num_puntura_no" value="'+num_no+'" '+disabled+'></input>';
            body+='</div>';
            body+='<div class="col-50 text-center">';
              body+='<input class="col center puntura_input" id="num_puntura_si" value="'+num_si+'" '+disabled+'></input>';
            body+='</div>';
        body+='</div>';

        var percentuale=self.calcolo_mosca(num_si,num_no);

        if (isNaN(percentuale)) {
          percentuale=0;
        }
        body+="<div class='col-xs-12'>";
          body+='<h2 class="text-center" id="calcolo_mosca">'+t("Infestazione al")+' <span id="percentuale_mosca">'+percentuale+'</span>%.</h2>';

          body+='<div id="motivation_div"></div>';
        body+="</div>";


      body+="</div>";
    body+="</div>";

    return body;
  }

  interact(survey){
    var self=this;

    jQuery(".btn-mosca").click(function() {
      var esito_puntura=jQuery(this).attr('puntura');

      var value=jQuery('#num_puntura_'+esito_puntura).val();
      value=parseInt(value)+1;
      jQuery('#num_puntura_'+esito_puntura).val(value);

      var num_si=parseInt(jQuery('#num_puntura_si').val());
      var num_no=parseInt(jQuery('#num_puntura_no').val());

      var percentuale=self.calcolo_mosca(num_si,num_no);
      jQuery('#percentuale_mosca').text(percentuale);

      var html='';
      html+='<h3 class="text-center motivation">'+num_si+' '+t("punture su")+' '+(num_no+num_si)+' '+t('olive osservate')+'.</h3>';

      if ((num_si+num_no)==0) {
        html+='<h3 class="text-center motivation">'+t('Osserva la tua prima oliva')+'..</h3>';
      }
      else if ((num_si+num_no)>0 && (num_si+num_no)<50) {
        html+='<h3 class="text-center motivation">'+t('Osserva almeno 50 olive')+'!</h3>';
      }
      else if ((num_si+num_no)>=50 && (num_si+num_no)<70) {
        html+='<h3 class="text-center motivation">'+t('Forza dai! Ancora qualche oliva')+'..</h3>';
      }
      else if ((num_si+num_no)>=70 && (num_si+num_no)<100) {
        html+='<h3 class="text-center motivation">'+t('A questo punto che fai? Non ci arrivi a 100 olive?!')+'</h3>';
      }
      else if ((num_si+num_no)>=100) {
        html+='<h3 class="text-center motivation">'+t('Ottimo lavoro! Mica tutti arrivano a leggere questo messaggio!')+' 🥳</h3>';
      }
      jQuery('#motivation_div').html(html);
    });

    jQuery(".puntura_input").change(function() {
      console.log("call");
      var num_si=parseInt(jQuery('#num_puntura_si').val());
      var num_no=parseInt(jQuery('#num_puntura_no').val());

      var percentuale=self.calcolo_mosca(num_si,num_no);
      jQuery('#percentuale_mosca').text(percentuale);
    });
  }

  calcolo_mosca(si, no){
    var totale=parseInt(si)+parseInt(no);
    if(totale>0){
      var percentuale=si/totale;
      return Math.round(percentuale*100);
    }
    else{
      return "-";
    }
  }
}

/*jshint esversion: 6 */

//Funzioni per gestire lo storage dei survey
import global from "../../global.js";
import storage from "../../storage.js";



//OK Funzione di lettura (e SOLA LETTURA) del setings
export function getSettings(){
  return storage.get();
}

//La funzione prende il setting e crea, se mancano, gli oggetti [survey_schema_farm][id_survey_schema]
export function setupSettings(s, id_farm, id_survey_schema){
  // debugger
  //Se non esiste l'azienda la crea
  if (typeof id_farm!='undefined') {
    if (typeof s['fields_'+id_farm]==='undefined') {
      s['fields_'+id_farm]=[];
    }
    if (typeof s['survey_schema_'+id_farm]==='undefined') {
      s['survey_schema_'+id_farm]={};
    }
    //Se non esiste il survey schema lo crea
    if (typeof s['survey_schema_'+id_farm][id_survey_schema]==='undefined') {
      s['survey_schema_'+id_farm][id_survey_schema]=[];
    }
  }
  return s;
}

export function selectSurveysStorage(id_farm, id_survey_schema){
  var s=storage.get();
  s=setupSettings(s, id_farm, id_survey_schema);
  return s['survey_schema_'+id_farm][id_survey_schema];
}

export function selectFieldsStorage(id_farm){
  var s=storage.get();
  return s['fields_'+id_farm];
}

//OK: return the fields according with distance
export function selectFieldsWithDistance(id_farm, id_crop, coordinates){
  var fields=selectFieldsStorage(id_farm);

  var surveyCoordinates=null;
  if(coordinates){
    surveyCoordinates=[coordinates.bufferedLatitude, coordinates.bufferedLongitude];
    if (Array.isArray(coordinates)) {
      surveyCoordinates=coordinates;
    }
  }

  console.log(fields);
  // debugger
  var return_fields=[];

  jQuery.each(fields, function(kf, field){

    var distance=9999999;
    var flag='NO_COORD';
    if(field.id_crop==id_crop){
      if (field.lat!=0 && field.lon!=0) {
        var field_coordinates=[field.lat,field.lon];

        if(surveyCoordinates){
          distance=getDistanceFromLatLonInKm(surveyCoordinates,field_coordinates);
          console.log("Distanza: "+(distance*1000)+" metri");

          flag='DIST'
        }
        else{
          distance=9999998;
          flag='COORD'
        }
      }

      return_fields.push({"distance": distance, "flag": flag, "field": field});
    }
   });

  return return_fields;

}



export function getDistanceFromLatLonInKm([lat1,lon1],[lat2,lon2]) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1);
  var a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180);
}



export function selectSurvey(id_farm, id_survey_schema, guid){
  var surveys= selectSurveysStorage(id_farm, id_survey_schema);

  var filtered=surveys.filter(function(s){return s.guid==guid;});
  if(filtered.length>0){
    return filtered[0];
  }
  else{
      return null;
  }
}

export function selectSurveySchema(id_survey_schema){
  var s=storage.get();
  var filtered=s.survey_schema.filter(function(f){return f.id_survey_schema==id_survey_schema;});

  if(filtered.length>0){
    return filtered[0];
  }
  else{
    return null;
  }
}

//l'update partial fa un extende dell'oggetto;M serve per aggiungree poche variabili lasciando l'oggetto originario
export function updatePartialSurvey(id_farm, id_survey_schema, guid, new_survey_partial, deep){
  new_survey_partial.sync=false;
  var survey=selectSurvey(id_farm, id_survey_schema, guid);
  if(survey===null){
      alert("Aggiornamento di survey parziale con id non esistente.|"+id_farm+"|"+guid+"|");
      global.crash_log("updatePartialSurvey.|"+id_farm+"|"+guid+"|");
  }
  else{
    //TODO Capire bene se deep=false sia la scelta corretta!!!
    //in questo modo se creaimo dei sotto oggetti non li sincronizzaa bene
    if (typeof deep=='undefined' && typeof deep!='boolean') {
      deep=false;
    }

    survey=jQuery.extend(deep, survey, new_survey_partial);
    updateSurvey(id_farm, id_survey_schema, guid, survey);
  }
}

export function updateField(id_farm, guid, field){
  var s=storage.get();
  var k_index=null;
  field.sync=false;

  jQuery.each(s['fields_'+id_farm],function(k,v){
    if(v.guid==guid){
      k_index=k;
    }
  });

  if(k_index!==null){
    s['fields_'+id_farm][k_index]=field;
    storage.save(s);
  }
  else{
    alert("Aggiornamento di field con guid non esistente.");
    global.crash_log(e,"updateField.|"+id_farm+"|"+guid+"|");
  }
}




//Get the survey position using a Guid
function getKeySurvey(s, id_farm, id_survey_schema,guid){
  var k_index=null;
  jQuery.each(s['survey_schema_'+id_farm][id_survey_schema],function(k,v){
    if(v.guid==guid){
      k_index=k;
    }
  });
  return k_index;
}

//OK Update/Replace a survey in the storage using a guid
export function updateSurvey(id_farm,id_survey_schema,guid,survey){
  var s=storage.get();

  var k_index=getKeySurvey(s, id_farm, id_survey_schema, guid);


  if(k_index!==null){
    s['survey_schema_'+id_farm][id_survey_schema][k_index]=survey;
    storage.save(s);
  }
  else{
    alert("Aggiornamento di survey con id non esistente.");
    global.crash_log(e,"updateSurvey.|"+id_farm+"|"+guid+"|");
  }
}


//Get the available crops
export function getCrops(){
  var s=storage.get();
  var crops={};
  jQuery.each(s.survey_schema,function(k,v){
    if(!crops[v.id_crop]){
      crops[v.id_crop]=v.crop_name
    }
  });
  return crops;
}

//Get the crop associated to the survey
export function getSurveyCrop(id_survey_schema){

  var s=storage.get();
  var id_crop=2;
  var crop_name="";
  try {
    jQuery.each(s.survey_schema,function(k,v){
      if (id_survey_schema==v.id_survey_schema) {
        id_crop=v.id_crop;
        crop_name=v.crop_name;
      }
    });
  }
  catch (e) {
    console.log(e,'id_crop -');
  }

  return {"id_crop": id_crop, "crop_name": crop_name}
}

// Elimina il survey dato
export function deleteSurvey(id_farm,id_survey_schema,guid){
  var s=storage.get();
  var k_index=getKeySurvey(s, id_farm, id_survey_schema, guid);

  if(k_index!==null){
    // Conferma per l'eliminazione
    global.app.dialog.confirm('Vuoi davvero eliminare il rilievo?', 'Attenzione!', function () {
      // Elimino dall'array il rilievo k-esimo
      s['survey_schema_'+id_farm][id_survey_schema].splice(k_index, 1);

      if (typeof s.toDelete=='undefined') {
        s.toDelete=[];
      }
      s.toDelete.push(guid);

      storage.save(s);
      //torno dov'ero per vedere la nuova lista di rilievi aggiornata
      window.history.back();

    },function(){
      window.history.back();
    });


  }
  else{
    alert("Eliminazione del survey con id non esistente non riuscita.");
    global.crash_log(e,"updateSurvey.|"+id_farm+"|"+guid+"|");
  }
}


export function saveDbmng_inline(id_farm, id_survey_schema,guid,oForm){
  var survey_schema=selectSurveySchema(id_survey_schema);
    var schema={};
    if(typeof survey_schema.schema=='string'){
      schema=JSON.parse(survey_schema.schema);
    }
    if(global.plus){
      schema=survey_schema.schema;
    }

  var scores=schema.scores;

  var toUpdate={};
  
  if(typeof oForm!=='undefined' && oForm!==null){
    jQuery.each(oForm,function(scheda,values){
      toUpdate[scheda]=values.getValue();
    });
  }

  var deep=false;
  var keys=Object.keys(toUpdate);

  updatePartialSurvey(id_farm, id_survey_schema, guid, toUpdate, deep);
}


export function saveDbmng(id_farm, id_survey_schema,guid,oForm){
  var survey_schema=selectSurveySchema(id_survey_schema);
  var schema={};
  if(typeof survey_schema.schema=='string'){
    schema=JSON.parse(survey_schema.schema);
  }
  if(global.plus){
    schema=survey_schema.schema;
  }

  
  var scores=schema.scores;

  var toUpdate={};
  if(typeof oForm!=='undefined' && oForm!==null){
    toUpdate=oForm.getValue(); //Merge dei due oggetti con jQuery (Vedi problema sul telefono di Massimo)

    if (id_survey_schema==5) {
      var totCatture=0;
      var totTrap=0;

      if (!isNaN(parseInt(toUpdate.catture_trap_1))) {
        totCatture+=parseInt(toUpdate.catture_trap_1);
        totTrap++;
      }
      if (!isNaN(parseInt(toUpdate.catture_trap_2))) {
        totCatture+=parseInt(toUpdate.catture_trap_2);
        totTrap++;
      }
      if (!isNaN(parseInt(toUpdate.catture_trap_3))) {
        totCatture+=parseInt(toUpdate.catture_trap_3);
        totTrap++;
      }


      var valoreMedio=0;
      if (totTrap!==0) {
        valoreMedio=totCatture/totTrap;
      }

      toUpdate.media_catture_trap=valoreMedio;
    }
  }

  var deep=true;
  var keys=Object.keys(toUpdate);

  toUpdate.weights={};

  jQuery.each(scores,function(k,d){
    jQuery.each(d.voc_val,function(i,v){
      if (keys.includes(k)) {
        if (toUpdate[k]==i) {
          toUpdate.weights[k]=v.weight;
        }
      }
    });
  });

  updatePartialSurvey(id_farm, id_survey_schema, guid, toUpdate, deep);
}

//Return  a progressive number
export function getProgressiveNumber(){
  var s=storage.get();
  if (!s.progressivoCampoGenerico) {
    s.progressivoCampoGenerico=0;
  }
  s.progressivoCampoGenerico++;
  storage.save(s);
  return ''+s.progressivoCampoGenerico;
}


//Inserisce il field in jStorage
export function insertFieldStorage(id_farm, id_survey_schema, field){
  var s=storage.get();
  s=setupSettings(s, id_farm, id_survey_schema);
  s['fields_'+id_farm].push(field);
  storage.save(s);
}

//l'update partial fa un extende dell'oggetto;M serve per aggiungree poche variabili lasciando l'oggetto originario
export function updatePartialField(id_farm, guid_field, new_field_partial, deep){
  new_field_partial.sync=false;
  var s=getSettings();

  var fields=selectFieldsStorage(s.id_farm);
  var field={};
  jQuery.each(fields,function(k,v){
    if (v.guid==guid_field) {
      field=v;
    }
  });

  if(field===null){
      alert("Aggiornamento di field parziale con guid non esistente.|"+id_farm+"|"+guid_field+"|");
      global.crash_log("updatePartialField.|"+id_farm+"|"+guid_field+"|");
  }
  else{
    //TODO Capire bene se deep=false sia la scelta corretta!!!
    //in questo modo se creaimo dei sotto oggetti non li sincronizzaa bene
    if (typeof deep=='undefined' && typeof deep!='boolean') {
      deep=false;
    }

    field=jQuery.extend(deep, field, new_field_partial);
    updateField(id_farm, guid_field, field);
  }
}



//Inserisce il survey in jStorage
export function insertSurveyStorage(id_farm, id_survey_schema, survey){
  var s=storage.get();
  s=setupSettings(s, id_farm, id_survey_schema);
  s['survey_schema_'+id_farm][id_survey_schema].push(survey);
  storage.save(s);
}

/*jshint esversion: 6 */

import exe_routes from "./routes.js";
import * as tools_routes from "./tools.js";
import global from './global.js';
import storage from './storage.js';
import t from './translate.js';


//TODO: verifcare se serve metterlo per determinate condizioni
// import 'bootstrap';
export default  function init_app(theme, project){

  // debugger



  // console.log(__appName__);
  if (global.is_cordova()) {
    console.log("is cordova");
    document.addEventListener('deviceready',function(){
      init_app_internal(theme, project);
    },false);
  }
  else {
    console.log("not cordova");
    init_app_internal(theme, project);
  }

  if (!global.is_cordova() && global.enablePWA!==false) {

    let newWorker;

    function showUpdateBar() {
      jQuery('#snackbar').show();
    }

    // debugger
    // The click event on the pop up notification

    // if ('serviceWorker' in navigator) {
    //   document.getElementById('reload').addEventListener('click', function(){
    //     if (newWorker) {
    //       newWorker.postMessage({ action: 'skipWaiting' });
    //     }
    //   });

    //   navigator.serviceWorker.register('./sw.js').then(reg => {
    //     reg.addEventListener('updatefound', () => {
    //       // A wild service worker has appeared in reg.installing!
    //       newWorker = reg.installing;

    //       newWorker.addEventListener('statechange', () => {
    //         // Has network.state changed?
    //         switch (newWorker.state) {
    //           case 'installed':
    //             if (navigator.serviceWorker.controller) {
    //               // new update available
    //               showUpdateBar();
    //             }
    //             // No update available
    //             break;
    //         }
    //       });
    //     });
    //   });

    //   let refreshing;
    //   navigator.serviceWorker.addEventListener('controllerchange', function () {
    //     if (refreshing) return;
    //     window.location.reload();
    //     refreshing = true;
    //   });
    // }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/sw.js')
        .then((reg) => {
          console.log('SW registered!', reg);
          jQuery('#debug').append('<br>SW registered!');
  
          //update every minute
          setInterval(function () {
            console.log('SW : check for update');
            reg.update();
          }, 6000);
        })
        .catch((err) => {
          console.log('Boo!', err);
          jQuery('#debug').append('<br>Boo! SW not registered!');
        });
  
  
      //check if there is a new service worker
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        function (event) {
          console.log('SW: controllerchange!');
          var c=confirm("C'è una nuova versione dell'app, aggiorniamo?");
          if (c==true) {
            location.reload(true);
          }
            
          // var html='<button class="refresh_page button button-primary" >Ricarica pagina</button>';
          // jQuery('#footer').html(html);
          // jQuery('#debug').append(html);
  
          // jQuery('.refresh_page').on('click',function(){
          //   jQuery('#debug').append('<br>Sto ricaricando la pagina adesso...');
          //   location.reload();
          //   jQuery('#debug').append('<br>Ho lanciato il comando... aspetta');
          // });
         }
      );
    }
  
  }

}




//funzione chiamata dall'html
function init_app_internal(theme, project){

  if (global.is_cordova()) {
    if (typeof FirebaseCrashlytics!=='undefined') {
      global.crashlytics = FirebaseCrashlytics.initialise();
    }
  }

  // console.log(project);
  global.theme=theme;
  global.project=project;
  global.loadingText=t('Caricamento in corso..');
  global.lockApp=false;

  if (typeof global.settingsName=='undefined') {
    global.settingsName='template_settings';
  }

  console.log(global.language);
  if (typeof global.language=='undefined') {
    global.language='en';
  }

  // console.log($);
  // console.log($.jStorage);
  global.jQuery=jQuery;
  if(jQuery){
    jQuery.jStorage=$.jStorage;
    // console.log(jQuery.jStorage);
  }

  storage.init({});

  var s=storage.get();
  if (s.language) {
    global.language=s.language;
  }

  //verifica se il porgetto prevede delle sidebars
  var theme_param={'div_id':'body_div', 'title': ''};


  if(typeof project.get_theme_param=='function'){
    theme_param = project.get_theme_param(theme_param);
    console.log(theme_param.sidebars);

    var tools={'label': t('Impostazioni'), 'icon_f7':'gear', 'href':'#tools'};
    if (theme_param.showTools!==false) {
      theme_param.sidebars.push(tools);
    }

  }

  //Crea la pagina html scheletro dell'app
  theme.init(theme_param);

  checkVersion();

  if (!global.lockApp) {
    //aggancia tutte le route
    exe_routes();
  }

  tools_routes.register();

  try {
    var urladdr="manifest.json";
    if (!global.is_cordova()) {
      if (typeof url!='undefined') {
        urladdr=url;
      }
    }
    console.log(urladdr);
    jQuery.ajax({
      url: urladdr,
      dataType:'text',
      success: function(text) {
        var manifest = JSON.parse(text);
        console.log("MANIFEST",manifest);

        if ((!s.installationDialog || s.installationDialog!='done' ) && global.iOS()) {
          setTimeout(function() {
            var installDialog = global.app.dialog.create({
              title:`${t('Installa')} ${manifest.name}`,
              destroyOnClose:true,
              buttons:[{
                close:true,
                text:t('Chiudi'),
                onClick:function(){
                  var s=storage.get();
                  s.installationDialog='done';
                  storage.save(s);
                }
              }],
              text: `
              1. ${t('Clicca su')} "<i class="f7-icons">square_arrow_up</i>"
              <br>
              2. ${t('Seleziona')} "<b>${t('Aggiungi alla schermata Home')} <i class="f7-icons">plus_square</i></b>"
              `,
              closeByBackdropClick: true,
              cssClass: 'iOSinstallPWA'
            });

            installDialog.open(true);

          }, 6000);
        }

        if (manifest.name) {
          document.title = manifest.name;
        }
        if (manifest.theme_color) {
          var metaThemeColor = document.querySelector("meta[name=theme-color]");
          metaThemeColor.setAttribute("content", manifest.theme_color);
        }

      },
      error:function(e){
        console.log("errore nel caricamento del manifest...");
      }
    });

  }
  catch (e) {
    console.log("manifest non andato a buon fine!");
  }

  if (s.user && ( !s.lastUpdate || s.lastUpdate < (new Date().getTime()-86400000) ) ) {
    location.hash='tools/sync';
  }

}


function checkVersion(){
  if (global.is_cordova() && cordova.platformId == 'android') {
    cordova.getAppVersion.getVersionNumber().then(function (local_version) {
      cordova.getAppVersion.getVersionCode(function(local_code){
        console.log("LOCAL CODE: "+local_code);
        console.log("LOCAL VERSION: "+local_version);

        var s=storage.get();
        var storage_version=s.version;

        console.log("STORAGE VERSION: "+storage_version);

        var firstTimeInThisVersion=true;
        if (typeof s.firstTimeInThisVersion!='undefined') {
          firstTimeInThisVersion=s.firstTimeInThisVersion;
        }

        if (storage_version!=local_version) {
          firstTimeInThisVersion=true;

          s.version=local_version;
          s.app_code = local_code;
          storage.save(s);
        }

        jQuery.ajax({
          type: 'GET',
          url: global.base_call+"get_app_version",
          dataType: "json",
          success: function(server_version){
            console.log("SERVER VERSION:",server_version);

            var release_note=server_version.data[0].release_note;
            var release_date=server_version.data[0].release_date;
            server_version=server_version.data[0].version;

            console.log("SERVER VERSION:",server_version);

            var changeVersion=false;

            if (typeof local_version!='undefined'){
              if (local_version!=server_version) {
                changeVersion=true;
              }
            }
            else {
              changeVersion=true;
            }

            var mainNumberSV=server_version.split('.')[0];
            var primaryNumberSV=server_version.split('.')[1];
            var secondaryNumberSV=server_version.split('.')[2];

            var mainNumberLV=local_version.split('.')[0];
            var primaryNumberLV=local_version.split('.')[1];
            var secondaryNumberLV=local_version.split('.')[2];

            if (parseInt(mainNumberSV)>parseInt(mainNumberLV) || parseInt(primaryNumberSV)>parseInt(primaryNumberLV)) {
              forceUpdate();
            }
            else if(parseInt(mainNumberSV)==parseInt(mainNumberLV) && parseInt(primaryNumberSV)==parseInt(primaryNumberLV) && parseInt(secondaryNumberSV) > parseInt(secondaryNumberLV)) {
              console.log("versione cambiata!");
              changedVersion();
            }
            else {
              console.log("versione non cambiata!");

              if (firstTimeInThisVersion==true) {
                console.log("prima volta nell'app");

                global.app.dialog.alert(release_note, "Note di rilascio", function(){location.reload();});

                s.release_date=formatDate(release_date);
                s.firstTimeInThisVersion=false;
                storage.save(s);
              }
            }
          },
          error: function(e) {
            console.log("Si è verificato un errore");
            // alert(e);
          }
        });
      });
    });
  }
  else {
    if (global.app_version) {
      var s=storage.get();
      s.version=global.app_version;
      storage.save(s);
    }
  }
}

function changedVersion(){
  if (location.hash=='') {

    var normal_bottom=jQuery('#version').parent().css('bottom');
    normal_bottom = normal_bottom.substring(0, normal_bottom.length - 2);

    var newBottom=(parseInt(normal_bottom)+44)+'px';
    jQuery('#version').parent().css('bottom',newBottom);
    jQuery('#sidebar_aggiorna_app').show();

    jQuery('#download_app').show();
    setTimeout(function(){
      jQuery('#download_app').addClass('hide-outside');
    }, 10000);
  }
  else {
    console.log("hide...");
    jQuery('#download_app').hide();
  }
  tools_routes.register();
}

function formatDate (input) {
  var datePart = input.split('-');
  var year = datePart[0];
  var month = datePart[1];
  var day = datePart[2];

  return day+'/'+month+'/'+year;
}

function forceUpdate(){
  console.log("UPDATE FORZATO");
  global.lockApp=true;

  var html='';
  html+='<div class="card">';
    html+='<div class="card-header">Aggiornare l\'APP</div>';
    html+='<div class="card-content card-content-padding">';
      html+="<div><b>La tua versione dell'APP è obsoleta, per continuare ad utilizzare l'APP, ti invitiamo ad aggiornarla al più presto.</b></div>";
    html+='</div>';
  html+='</div>';

  html+='<div style="margin-top:'+(window.innerHeight-460)+'px" class="arrow-down">';
    html+='<i style="margin: auto; font-size:80px;" class="f7-icons">arrow_down</i>';
  html+='</div>';

  var content={'title': '', 'content': html,exclude_card:true};
  global.theme.render(content);

  jQuery('#download_app').show();
}
